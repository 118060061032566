import {
    BOARD_LIST_REQUEST, 
    BOARD_LIST_SUCCESS, 
    BOARD_LIST_FAIL,

    BOARD_DETAILS_REQUEST, 
    BOARD_DETAILS_SUCCESS, 
    BOARD_DETAILS_FAIL,

    BOARD_CREATE_REQUEST, 
    BOARD_CREATE_SUCCESS, 
    BOARD_CREATE_FAIL,
    BOARD_CREATE_RESET,


} from '../constants/boardConstants'
import axios from 'axios'

export const listBoards = ()=> async (dispatch)=>{
    
    try{
        dispatch({type: BOARD_LIST_REQUEST})
        const {data} = await axios.get('/api/boards/')

        dispatch({
            type: BOARD_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: BOARD_LIST_FAIL,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail 
            : error.message,
        })
    }
}

export const listBoardDetails = ({id})=> async (dispatch)=>{
    try{
        dispatch({type: BOARD_DETAILS_REQUEST})
       
        const {data} = await axios.get(`/api/boards/${id}`)

        dispatch({
            type: BOARD_DETAILS_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: BOARD_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail 
            : error.message,
        })
    }
}


export const createBoard = (name, description) => async (dispatch, getState) => {
    try {
        dispatch({
            type: BOARD_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/boards/add/`,
            {'name':name,  'description':description},
            config
        )

        dispatch({
            type: BOARD_CREATE_SUCCESS,
            payload: data
        })



    } catch (error) {
        dispatch({
            type: BOARD_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



