import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux'
import { Form, Button, Image, Row, Col } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';

import { listHfs } from '../actions/HeadFirstActions'
import { listSubjects } from '../actions/subjectActions'
import { listClassnames } from '../actions/classnameActions'
import { listBoards } from '../actions/boardActions'
import FormContainer from '../components/FormContainer'
import Badge from 'react-bootstrap/Badge';

import {useNavigate} from 'react-router-dom';


function HeadFirstImagesDisplayScreen() {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [selectedBoard, setSelectedBoard] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [description, setDescription] = useState('')

  const hfList = useSelector(state => state.hfList)
  const {loading, error, headFirstImages} = hfList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const subjectList = useSelector(state => state.subjectList)
  const {loading:subjectListLoading, error:subjectListError, subjects} = subjectList

  const boardList = useSelector(state => state.boardList)
  const {loading:boardLoading, error:boardError, boards} = boardList

  const classnameList = useSelector(state => state.classnameList)
  const {loading:classnamesLoading, error:classnamesError, classnames} = classnameList

  const isNew = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const differenceInHours = (now - createdDate) / (1000 * 60 * 60);
    return differenceInHours < 36;
  };

  useEffect(()=>{

    if (userInfo) {
        dispatch(listHfs())
        dispatch(listSubjects())
        dispatch(listClassnames())
        dispatch(listBoards())
    } else {
        navigate('/login')
    }
  }, [dispatch, navigate, userInfo])

  const filterRevisions = () => {
    return headFirstImages.filter((revision) => {
        return (
            (!selectedBoard || revision.board === selectedBoard) &&
            (!selectedClass || revision.classname === selectedClass) &&
            (!selectedSubject || revision.subject === selectedSubject) &&
            (description==="" || revision.description.toLowerCase().includes(description.toLowerCase()))
        );
    });
};

const filteredRevisions = filterRevisions();
  
    return (
      <div style={{  whiteSpace: 'nowrap' }}>
        <h4>Head First Revisions</h4><hr></hr>
     
        <Form className="filters">
          <Row>
            <Col  md={3}>
            <Form.Group className="filter" controlId="formBoard">
                  <Form.Control 
                    as="select" 
                    value={selectedBoard} 
                    onChange={(e) => setSelectedBoard(e.target.value)} 
                    required
                  >
                    <option value="">Select Board</option>
                    {boards.map(board => (
                      <option key={board.id} value={board.id}>{board.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
           
            </Col>
            <Col  md={3}>
            <Form.Group className="filter" controlId="formClassname">
                  <Form.Control 
                    as="select" 
                    value={selectedClass} 
                    onChange={(e) => setSelectedClass(e.target.value)} 
                    required
                  >
                    <option value="">Select Class</option>
                    {classnames.map(classname => (
                      <option key={classname.id} value={classname.id}>{classname.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            </Col>
            <Col  md={3}>
              <Form.Group className="filter" controlId="formSubject">
                  <Form.Control 
                    as="select" 
                    value={selectedSubject} 
                    onChange={(e) => setSelectedSubject(e.target.value)} 
                    required
                  >
                    <option value="">Select subject</option>
                    {subjects.map(subject => (
                      <option key={subject.id} value={subject.id}>{subject.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            </Col>
            <Col  md={3}>
              <Form.Group className="filter" controlId="formDescription">
              
                 <Form.Control class="form-control" required type= 'input' placeholder='key word' Value={description} 
                onChange={(e)=> setDescription(e.target.value)} rows="3"></Form.Control>
            </Form.Group>
            </Col>
          </Row>
            
        </Form>
      
        <hr></hr>
        {filteredRevisions.map(revision => (
      
      <div style={{ }}>
     
        <h6>{revision.topic}&nbsp; &nbsp; {isNew(revision.createdAt) && <Badge bg="warning" >New</Badge>}</h6>
        
       
        <small className="mb-2 text-muted">{revision.description}</small>
        <img 
                        key={revision.id} 
                        src={revision.image} 
                        
                        style={{ display: 'block', maxWidth: '80%', maxHeight: '80%', margin: '2px auto' }} 
                    />
          
      <hr></hr>
    </div>
           
           
        ))}

        
      </div>
    );
}

export default HeadFirstImagesDisplayScreen
