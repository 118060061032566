
import {
    CLASSNAME_LIST_REQUEST, 
    CLASSNAME_LIST_SUCCESS, 
    CLASSNAME_LIST_FAIL,
    
    CLASSNAME_CREATE_REQUEST, 
    CLASSNAME_CREATE_SUCCESS, 
    CLASSNAME_CREATE_FAIL,
    CLASSNAME_CREATE_RESET,

    CLASSNAME_DELETE_REQUEST,
    CLASSNAME_DELETE_SUCCESS,
    CLASSNAME_DELETE_FAIL, 

    
} from '../constants/classnameConstants'
export const classnameCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CLASSNAME_CREATE_REQUEST:
            return {
                loading: true
            }

        case CLASSNAME_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                classname: action.payload
            }

        case CLASSNAME_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLASSNAME_CREATE_RESET:
            return {}


        default:
            return state
    }
}

export const classnameListReducer = (state = {classnames:[]}, action)=>{
    switch(action.type){
        case CLASSNAME_LIST_REQUEST:
            return {loading:true, classnames:[]}

        case CLASSNAME_LIST_SUCCESS:
            return {loading:false, classnames:action.payload}

        case CLASSNAME_LIST_FAIL:
            return {loading:false, error:action.payload}

        default: 
            return state
    }
}

export const classnameDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CLASSNAME_DELETE_REQUEST:
            return { loading: true }

        case CLASSNAME_DELETE_SUCCESS:
            return { loading: false, success: true }

        case CLASSNAME_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}