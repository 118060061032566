import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import {LinkContainer} from 'react-router-bootstrap'
import { Form, Button, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { listHeadFirstDetails, updateHeadFirst } from '../actions/HeadFirstActions'
import { HFIMAGE_UPDATE_RESET } from '../constants/HeadFirstConstants'
import {useNavigate, useParams }from 'react-router-dom';

import { listSubjects } from '../actions/subjectActions'
import { listClassnames } from '../actions/classnameActions'
import { listBoards } from '../actions/boardActions'

function HeadFirstRevisionEditScreen() {
  const {id} = useParams()

  const [board, setSelectedBoard] = useState('')
  const [classname, setSelectedClass] = useState('')
  const [subject, setSelectedSubject] = useState('')
 
  const [image, setImage] = useState('')
  
  const [topic, setTopic] = useState('')
  const [description, setDescription] = useState('')
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate();
  

  const hfDetails = useSelector(state => state.hfDetails)
  const { error, loading, hfr } = hfDetails

  const hfUpdate = useSelector(state => state.hfUpdate)
  const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = hfUpdate

  const subjectList = useSelector(state => state.subjectList)
  const {loading:subjectListLoading, error:subjectListError, subjects} = subjectList

  const boardList = useSelector(state => state.boardList)
  const {loading:boardLoading, error:boardError, boards} = boardList

  const classnameList = useSelector(state => state.classnameList)
  const {loading:classnamesLoading, error:classnamesError, classnames} = classnameList

useEffect(() => {
  dispatch(listSubjects())
  dispatch(listClassnames())
  dispatch(listBoards())
  if(successUpdate){
    dispatch({ type: HFIMAGE_UPDATE_RESET })
        navigate('/admin/headfirstrevisions')
  } else{
    if(!hfr.board || hfr._id != Number(id)){
        dispatch(listHeadFirstDetails(id))
      } else{
        setSelectedBoard(hfr.board)
        setImage(hfr.image)
        setSelectedClass(hfr.classname)
        setSelectedSubject(hfr.subject)
        setTopic(hfr.topic)
        setDescription(hfr.description)
       
      }
  }
  
}, [hfr, id, successUpdate, navigate])

const submitHandler = (e) => {
  e.preventDefault()
  dispatch(updateHeadFirst({
      _id: id,
      board,
      classname,
      image,
      topic,
      subject,
      description
  }))
}

const uploadFileHandler = async (e) => {
  const file = e.target.files[0]
  const formData = new FormData()

  formData.append('image', file)
  formData.append('hfr_id', id)

  setUploading(true)

  try {
      const config = {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }

      const { data } = await axios.post('/api/headfirstimages/upload/', formData, config)


      setImage(data)
      setUploading(false)

  } catch (error) {
      setUploading(false)
  }
}


  return (
    <div>
      <FormContainer>
        <h5>Edit Head First Revision 
          <LinkContainer to='/admin/headfirstrevisions'>
              <button type="button" className="btn-close float-end" aria-label="Close"></button>
          </LinkContainer>
      </h5>
    <hr></hr>

    {loadingUpdate && <Loader />}
                {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (<Form onSubmit={submitHandler}>
                        <Form.Group controlId="formBoard">
              <Form.Label>Board</Form.Label>
              <Form.Control 
                as="select" 
                value={board} 
                onChange={(e) => setSelectedBoard(e.target.value)} 
                required
              >
                <option value="">Select Board</option>
                {boards.map(board => (
                  <option key={board.id} value={board.id}>{board.name}</option>
                ))}
            </Form.Control>
            </Form.Group>

            <Form.Group controlId="formClass">
              <Form.Label>Class</Form.Label>
              <Form.Control 
                as="select" 
                value={classname} 
                onChange={(e) => setSelectedClass(e.target.value)} 
                required
              >
                <option value="">Select Class</option>
                {classnames.map(cls => (
                  <option key={cls.id} value={cls.id}>{cls.name}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control 
                as="select" 
                value={subject} 
                onChange={(e) => setSelectedSubject(e.target.value)} 
                required
              >
                <option value="">Select Subject</option>
                {subjects.map(subject => (
                  <option key={subject.id} value={subject.id}>{subject.name}</option>
                ))}
              </Form.Control>
            </Form.Group>

                            <Form.Group controlId='topic'>
                                <Form.Label>Topic</Form.Label>
                                <Form.Control

                                    type='text'
                                    placeholder='Enter topic'
                                    value={topic}
                                    onChange={(e) => setTopic(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>


                            <Form.Group controlId='description'>
                                <Form.Label>Description</Form.Label>
                                <Form.Control

                                    type='text'
                                    placeholder='Enter description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>


                         
                              <Image  className='py-3 my-3 px-3 headfirst-img-edit' src={image} fluid thumbnail/>
                           
                            
                            <Form.Group controlId='image'>
                                <Form.Label>Image</Form.Label>
                                <input type="file" class="form-control" id="image-input" onChange={uploadFileHandler}/>
           
                            </Form.Group>

                            <Button type='submit' variant='primary' className='mt-3'>
                                Update
                        </Button>

                    </Form>)}

      </FormContainer>
    </div>
  )
}

export default HeadFirstRevisionEditScreen
