import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import {thunk} from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {boardListReducer, boardDetailsReducer, boardCreateReducer} from './reducers/boardReducers'
import {subjectCreateReducer, subjectListReducer} from './reducers/subjectReducers'
import {classnameCreateReducer, classnameListReducer, classnameDeleteReducer} from './reducers/classnameReducers'

import { userLoginReducer, 
    userRegisterReducer, 
    userDetailsReducer, 
    updateUserProfileReducer, 
    userListReducer, 
    activeUserListReducer,
    userDeleteReducer,
    userUpdateReducer,
    pswdResetReqCreateReducer,
    pswdResetReqListReducer
} from './reducers/userReducers'

import {assignmentListReducer, 
    assignmentCreateReducer, 
    assignmentListMyReducer,
    assignmentDetailsReducer,
    assignmentResourcDetailsReducer,
    assignmentDeleteReducer,
} from './reducers/assignmentReducers'

import {
    planCreateReducer, 
    planListReducer,
    planPayReducer,
    planDetailsReducer,
    userPlanDetailsReducer
} from './reducers/planReducers'

import {
    hfCreateReducer,
    hfUpdateReducer,
    hfListReducer,
    hfDetailsReducer,
    headFirstDeleteReducer,

} from './reducers/HeadFirstReducers'

const reducer = combineReducers({
    boardList: boardListReducer,
    boardDetails: boardDetailsReducer,

    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    updateUserProfile: updateUserProfileReducer,
    userList: userListReducer,
    activeUserList: activeUserListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    pswdResetReqCreate: pswdResetReqCreateReducer,
    pswdResetReqList: pswdResetReqListReducer,

    assignmentList: assignmentListReducer,
    assignmentCreate: assignmentCreateReducer,
    assignmentsListMy: assignmentListMyReducer,
    assignmentDetails: assignmentDetailsReducer,
    assignmentDelete: assignmentDeleteReducer,
    assignmentResourceDetails: assignmentResourcDetailsReducer,

    planList: planListReducer,
    planCreate: planCreateReducer,
    planDetails: planDetailsReducer,
    userPlanDetails: userPlanDetailsReducer,
    
    planPay: planPayReducer,

    hfCreate: hfCreateReducer,
    hfUpdate: hfUpdateReducer,
    hfList: hfListReducer,
    hfDetails: hfDetailsReducer,
    headFirstDelete: headFirstDeleteReducer,

    subjectCreate: subjectCreateReducer,
    subjectList: subjectListReducer,

    classnameCreate: classnameCreateReducer,
    classnameList: classnameListReducer,
    classnameDelete: classnameDeleteReducer,
    boardCreate: boardCreateReducer,


})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
    userLogin:{userInfo:userInfoFromStorage}
}

const middleware = [thunk]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; 
const store = createStore(reducer, initialState,
    composeEnhancers(applyMiddleware(...middleware)))

    
export default store