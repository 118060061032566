import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {Container, Row, Col, Card} from 'react-bootstrap';
import {listBoards} from '../actions/boardActions'


import StudentWriting from "../StudentWriting.jpg";
import TeacherEvaluating from "../TeacherEvaluating.jpg";

function HomeScreen() {
  const dispatch = useDispatch()
  const boardList = useSelector(state => state.boardList)
  const {error, loading, boards} = boardList
  useEffect(()=>{
    dispatch(listBoards())
    
  }, [dispatch])

  
  return (
    <div>
      
           <Row>
              <Col xs={12} md={12} className=''>
                      <div className=' mainpage-heading text-center'><i>we<b>e</b>val</i><span> - Your Learning Evaluation Companion</span></div>
                      <p className=''>weeval is a comprehensive web application designed to streamline the learning evaluation process for both students and teachers.</p>
                      <p>weeval Simplifies the assignment creation, submission, evaluation, and feedback process. Provides transparency between students and teachers regarding assignment progress and feedback.</p>
                      <p>weeval allows customization of assignments based on education board, class, subject, and topic. The  interface is intuitive  for seamless navigation and interaction.</p>
              </Col>
             
          </Row>
          <Row>
              
              <Col xs={12} md={6}>
                  <h5>For Students:</h5>

                  <p>Students log in to their personalized accounts.</p>
                  <p>Upon login, students are greeted with an empty assignment dashboard.</p>
                  <p>Students can create new assignments by clicking the "New Assignment" button,
                     where they fill in details such as education board, class, subject, 
                     chapter, topic, subtopic, and description.
                  </p>
                  <p>After submitting the assignment details, the dashboard displays a table with the newly created assignment. 
                    Students can click on the row to view the assignment details.
                  </p>
                  <p>Upon viewing the assignment, if a question paper is uploaded by the teacher, 
                    students are prompted to upload their answer paper. After uploading, a teacher or a staff 
                    member evaluates and uploads the evaluated copy for students to check.
                    </p>
                  
                  
              </Col>
          
              <Col xs={12} md={6}>
              <h5>For weeval Teachers/Staff:</h5>

                  <p>Teachers log in to their staff accounts.</p>
                  <p>Staff members have access to a dashboard displaying all assignment requests 
                    from students in a table format.
                    </p>
                  <p>By clicking on each row, staff members can view the assignment details.</p> 
                 <p>Staff members can upload question papers for the respective assignments. 
                 
                  </p>
                  <p>
                  Upon student submission of answer papers, staff members are prompted to evaluate and upload the corrected paper.
                    </p>

              </Col>
              
           </Row>
           
           
           
         
    </div>
  )
}

export default HomeScreen