import React, { useState, useEffect } from 'react'
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {LinkContainer} from 'react-router-bootstrap'
import { getUserDetails, updateUser  } from '../actions/userActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {useNavigate, useParams }from 'react-router-dom';
import { getAssignmentDetails, getAssignmentResourceDetails } from '../actions/assignmentActions'
import axios from 'axios'

function AssignmentScreen() {
    const {id} = useParams()
    const {userId} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const assignmentDetails = useSelector(state => state.assignmentDetails)
    const { assignment, error, loading } = assignmentDetails

    const assignmentResourceDetails = useSelector(state => state.assignmentResourceDetails)
    const { assignmentResource, error: assignmentResourceError , loading: assignmentResourceLoading } = assignmentResourceDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const [qpFile, setQpFile] = useState(null)
    const [qpUploaded, setQpUploaded] = useState(false)

    const [rpFile, setRpFile] = useState(null)
    const [rpUploaded, setRpUploaded] = useState(false)

    const [epFile, setEpFile] = useState(null)
    const [epUploaded, setEpUploaded] = useState(false)

    const userDetails = useSelector(state => state.userDetails)
    const { error:userError, loading:userLoading, user } = userDetails

    useEffect(() => {

        if (!userInfo) {
            navigate('/login')
        }

        if ( assignment._id !== id) {
            dispatch(getAssignmentDetails(id))
            dispatch(getAssignmentResourceDetails(id))
            dispatch(getUserDetails(userId))
        }
    }, [dispatch])

    const handleDownloadQpClick = () =>{
        window.open(`/api/assignmentresources/downloadquestionsimage/${assignmentResource._id}/`, '_blank')
    }

    const handleDownloadRpClick = () =>{
        window.open(`/api/assignmentresources/downloadresponseimage/${assignmentResource._id}/`, '_blank')
    }

    const handleDownloadEpClick = () =>{
        window.open(`/api/assignmentresources/downloadevaluatedimage/${assignmentResource._id}/`, '_blank')
    }

    const handleQpFileChange = (e) => {
        setQpFile(e.target.files[0])
    };

    const handleRpFileChange = (e) => {
        setRpFile(e.target.files[0])
    };

    const handleEpFileChange = (e) => {
        setEpFile(e.target.files[0])
    };

    const handleQpSubmit =async (e) => {
        e.preventDefault()
       
        const formData = new FormData()
        formData.append('file', qpFile)
        try {
            await axios.put(`/api/assignmentresources/uploadqp/${assignmentResource._id}/`, formData, {
                headers: {
                    'Content-Typr': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            setQpUploaded(true)
            window.location.reload()
           
             
        } catch(error){

        }
    };

    const handleRpSubmit =async (e) => {
        e.preventDefault()
       
        const formData = new FormData()
        formData.append('file', rpFile)
        try {
            await axios.put(`/api/assignmentresources/uploadrp/${assignmentResource._id}/`, formData, {
                headers: {
                    'Content-Typr': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            setRpUploaded(true)
            window.location.reload()
           
             
        } catch(error){

        }
    };

    const handleEpSubmit =async (e) => {
        e.preventDefault()
       
        const formData = new FormData()
        formData.append('file', epFile)
        try {
            await axios.put(`/api/assignmentresources/uploadep/${assignmentResource._id}/`, formData, {
                headers: {
                    'Content-Typr': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            setEpUploaded(true)
            window.location.reload()
           
             
        } catch(error){

        }
    };

    return loading ? (
        <Loader />
    ) : error ? (
        <Message variant='danger'>{error}</Message>
    ) : (
                <div>
                          <h5>Assignment Details 
                          {!userInfo.isAdmi?(<LinkContainer to='/admin/assignmentlist'>
                                <button type="button" className="btn-close float-end" aria-label="Close"></button>
                            </LinkContainer>):(<LinkContainer to='/userscreen'>
                                <button type="button" className="btn-close float-end" aria-label="Close"></button>
                            </LinkContainer>)}
                            
                            </h5> 
                            
                           <hr></hr>
                    <Row>
                        <Col md={4}>
                            <Card>
                                <ListGroup variant='flush'>
                                
                                    <ListGroup.Item>
                                        <Row>
                                            
                                            <Col><b>{user.name}</b></Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>Board:</Col>
                                            <Col>{assignment.board}</Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col>Class:</Col>
                                            <Col>{assignment.classname}</Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col>Subject:</Col>
                                            <Col>{assignment.subject}</Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col>Topic:</Col>
                                            <Col>{assignment.topic}</Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col>Description:</Col>
                                            <Col>{assignment.description}</Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                               
                            </Card>
                        </Col>
                        <Col md={8}>
                       
                        <ListGroup variant=''>
                           { userInfo.isAdmin? 
                            (
                                assignment.questions_image_submitted? 
                                (
                                    assignment.response_image_submitted? 
                                    (
                                        assignment.evaluation_image_submitted? 
                                        (
                                            <span>
                                                <ListGroup.Item key='i'>
                                                    <span className='form-control text-center btn btn-light' onClick={handleDownloadQpClick}>Download <b>{assignmentResource.name} 
                                                    </b>&nbsp;Question Paper&nbsp; <i class="fa-solid fa-download"></i></span>       
                                                </ListGroup.Item>
                                                <ListGroup.Item key='i'>
                                                    <span className='form-control text-center btn btn-light' onClick={handleDownloadRpClick}>Download <b>{assignmentResource.name} 
                                                    </b>&nbsp;Anwser paper&nbsp; <i class="fa-solid fa-download"></i></span>         
                                                </ListGroup.Item>
                                                <ListGroup.Item key='i'>
                                                    <span className='form-control text-center btn btn-light' onClick={handleDownloadEpClick}>Download <b>{assignmentResource.name} 
                                                    </b>&nbsp;Evaluated paper&nbsp; <i class="fa-solid fa-download"></i></span>               
                                                </ListGroup.Item>
                                            </span>
                                        )
                                        :(
                                            <span>
                                                 <ListGroup.Item key='i'>
                                                    <span className='form-control text-center btn btn-light' onClick={handleDownloadQpClick}>Download <b>{assignmentResource.name} 
                                                    </b>&nbsp;Question Paper&nbsp; <i class="fa-solid fa-download"></i></span>       
                                                </ListGroup.Item>
                                                <ListGroup.Item key='i'>
                                                    <span className='form-control text-center btn btn-light' onClick={handleDownloadRpClick}>Download <b>{assignmentResource.name} 
                                                    </b>&nbsp;Anwser paper&nbsp; <i class="fa-solid fa-download"></i></span>         
                                                </ListGroup.Item>
                                                <ListGroup.Item key='i'>
                                                    <Message variant='info'><h6>No evaluated paper is updated yet. Please upload an evaluated paper.</h6></Message>
                                                    <h6>Upload evaluated paper</h6>
                                                    <form>
                                                        <input type="file" class="form-control" id={assignmentResource.id} onChange={handleEpFileChange}/>
                                                        <button type='submit' className='form-control btn btn-light my-2' onClick={handleEpSubmit}>Upload&nbsp;<i class="fa-solid fa-upload"></i></button>
                                                    </form>
                                                </ListGroup.Item>
                                            </span>
                                        )
                                    )
                                    :(
                                        <span>
                                            <ListGroup.Item key='i'>
                                                 <span className='form-control text-center btn btn-light' onClick={handleDownloadQpClick}>Download <b>{assignmentResource.name} 
                                                </b>&nbsp;Question Paper&nbsp; <i class="fa-solid fa-download"></i></span>       
                                            </ListGroup.Item>
                                            <ListGroup.Item key='i'>
                                                <Message variant='info'><h6>No answer paper is updated yet.</h6></Message> 
                                            </ListGroup.Item>
                                        </span>
                                    )
                                )
                                :(
                                    <span>
                                        <ListGroup.Item key='i'>
                                            <Message variant='info'><h6>No question paper is updated yet. Please upload a question paper.</h6></Message>
                                            <h5>Upload Question Paper</h5>
                                            <form>
                                                <input type="file" class="form-control" id={assignmentResource.id} onChange={handleQpFileChange}/>
                                                <button type='submit' className='form-control btn btn-light my-2' onClick={handleQpSubmit}>Upload&nbsp;<i class="fa-solid fa-upload"></i></button>
                                            </form>
                                        </ListGroup.Item>
                                        
                                    </span>
                                )
                            )
                            :(
                                assignment.questions_image_submitted? 
                                (
                                    assignment.response_image_submitted? 
                                    (
                                        assignment.evaluation_image_submitted? 
                                        (
                                            <span>
                                                <ListGroup.Item key='i'>
                                                    <span className='form-control text-center btn btn-light' onClick={handleDownloadQpClick}>Download <b>{assignmentResource.name} 
                                                    </b>&nbsp;Question Paper&nbsp; <i class="fa-solid fa-download"></i></span>       
                                                </ListGroup.Item>
                                                <ListGroup.Item key='i'>
                                                    <span className='form-control text-center btn btn-light' onClick={handleDownloadRpClick}>Download <b>{assignmentResource.name} 
                                                    </b>&nbsp;Anwser paper&nbsp; <i class="fa-solid fa-download"></i></span>         
                                                </ListGroup.Item>
                                                <ListGroup.Item key='i'>
                                                    <span className='form-control text-center btn btn-light' onClick={handleDownloadEpClick}>Download <b>{assignmentResource.name} 
                                                    </b>&nbsp;Evaluated paper&nbsp; <i class="fa-solid fa-download"></i></span>               
                                                </ListGroup.Item>
                                            </span>
                                        )
                                        :(
                                            <span>
                                                <ListGroup.Item key='i'>
                                                    <span className='form-control text-center btn btn-light' onClick={handleDownloadQpClick}>Download <b>{assignmentResource.name} 
                                                    </b>&nbsp;Question Paper&nbsp; <i class="fa-solid fa-download"></i></span>       
                                                </ListGroup.Item>
                                                <ListGroup.Item key='i'>
                                                    <span className='form-control text-center btn btn-light' onClick={handleDownloadRpClick}>Download <b>{assignmentResource.name} 
                                                    </b>&nbsp;Anwser paper&nbsp; <i class="fa-solid fa-download"></i></span>         
                                                </ListGroup.Item>
                                                <ListGroup.Item key='i'>
                                                    <Message variant='info'><h6>No eavluated paper is updated yet.</h6></Message> 
                                                </ListGroup.Item>
                                            </span>
                                        )
                                    )
                                    :(
                                        <span>
                                            <ListGroup.Item key='i'>
                                                    <span className='form-control text-center btn btn-light' onClick={handleDownloadQpClick}>Download <b>{assignmentResource.name} 
                                                    </b>&nbsp;Question Paper&nbsp; <i class="fa-solid fa-download"></i></span>       
                                            </ListGroup.Item>
                                            <ListGroup.Item key='i'>
                                                <Message variant='info'><h6>No answer paper is updated yet. Please upload an answer paper.</h6></Message>
                                                <h6>Upload answer paper</h6>
                                                <form>
                                                    <input type="file" class="form-control" id={assignmentResource.id} onChange={handleRpFileChange}/>
                                                    <button type='submit' className='form-control btn btn-light my-2' onClick={handleRpSubmit}>Upload&nbsp;<i class="fa-solid fa-upload"></i></button>
                                                </form>
                                            </ListGroup.Item>

                                        </span>
                                    )
                                )
                                :(
                                    <span>
                                             <ListGroup.Item key='i'>
                                                <Message variant='info'><h6>No question paper is updated yet.</h6></Message>
                                            </ListGroup.Item>
                                    </span>
                                )
                            )

                        }
                    </ListGroup> 
                        </Col>

                    </Row>
                </div>
            )
}

export default AssignmentScreen
