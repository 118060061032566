import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import {Form, Button, Row, Col} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import Loader from '../components/Loader'
import { createAssignment } from '../actions/assignmentActions'
import FormContainer from '../components/FormContainer'
import {useNavigate} from 'react-router-dom';
import Message from '../components/Message'
import{ASSIGNMENT_CREATE_RESET} from '../constants/assignmentConstants'
import {LinkContainer} from 'react-router-bootstrap'

import { listSubjects } from '../actions/subjectActions'
import { listClassnames } from '../actions/classnameActions'
import { listBoards } from '../actions/boardActions'

function CreateAssignmentScreen(location) {
  const [board, setSelectedBoard] = useState('');
  const [classname, setSelectedClass] = useState('');
  const [subject, setSelectedSubject] = useState('');
  const [topic, setTopic] = useState('')
  const [description, setDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const redirect = location.search ? location.search.split('=')[1] : '/'

  const navigate = useNavigate();

  const assignmentCreate = useSelector(state => state.assignmentCreate)
  const {assignment, error, success} = assignmentCreate

  const subjectList = useSelector(state => state.subjectList)
  const {loading, error:subjectListError, subjects} = subjectList

  const boardList = useSelector(state => state.boardList)
  const {loading:boardLoading, error:boardError, boards} = boardList

  const classnameList = useSelector(state => state.classnameList)
  const {loading:classnamesLoading, error:classnamesError, classnames} = classnameList


  useEffect(()=>{
    dispatch(listSubjects())
    dispatch(listClassnames())
    dispatch(listBoards())
    if(success){
        navigate('/userscreen')
        dispatch({type:ASSIGNMENT_CREATE_RESET})
    }
  },[success, navigate])
  
  const submitHandler = (e) => {
    e.preventDefault()
    setIsLoading(true);
      dispatch(createAssignment(board, classname, subject,topic, description))  
  }

    return (
    <FormContainer>
        <h5>New Assignment <LinkContainer to='/userscreen'>
                                <button type="button" className="btn-close float-end" aria-label="Close"></button>
                            </LinkContainer></h5>
        
        <hr></hr>
      
        <Form onSubmit={submitHandler}>
        <Form.Group controlId="formBoard">
              <Form.Label>Board</Form.Label>
              <Form.Control 
                as="select" 
                value={board} 
                onChange={(e) => setSelectedBoard(e.target.value)} 
                required
              >
                <option value="">Select Board</option>
                {boards.map(board => (
                  <option key={board.id} value={board.id}>{board.name}</option>
                ))}
            </Form.Control>
            </Form.Group>

            <Form.Group controlId="formClass">
              <Form.Label>Class</Form.Label>
              <Form.Control 
                as="select" 
                value={classname} 
                onChange={(e) => setSelectedClass(e.target.value)} 
                required
              >
                <option value="">Select Class</option>
                {classnames.map(cls => (
                  <option key={cls.id} value={cls.id}>{cls.name}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control 
                as="select" 
                value={subject} 
                onChange={(e) => setSelectedSubject(e.target.value)} 
                required
              >
                <option value="">Select Subject</option>
                {subjects.map(subject => (
                  <option key={subject.id} value={subject.id}>{subject.name}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='classname'>
                <Form.Label> Topic</Form.Label>
                <Form.Control required type= 'input' placeholder='Enter Topic' Value={topic} 
                onChange={(e)=> setTopic(e.target.value)}></Form.Control>

            </Form.Group>

            <Form.Group controlId='classname'>
                <Form.Label> Description</Form.Label>
                 <textarea class="form-control" required type= 'textarea' placeholder='Description' Value={description} 
                onChange={(e)=> setDescription(e.target.value)} rows="3"></textarea>

            </Form.Group>
            <br></br>
            <Button type = 'submit' variant='primary' disabled={isLoading}>Create Assignment</Button>
        </Form>
        
    
    </FormContainer>
  )
}

export default CreateAssignmentScreen