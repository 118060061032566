import React, {useState, useEffect} from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import {Table, Button, Row, Col} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'



import Loader from '../components/Loader'
import { listHfs, deleteHeadFirst } from '../actions/HeadFirstActions'
import FormContainer from '../components/FormContainer'
import {useNavigate} from 'react-router-dom';
import Message from '../components/Message'

function HeadFirstImageStaffScreen() {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const hfList = useSelector(state => state.hfList)
  const {loading, error, headFirstImages} = hfList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const headFirstDelete = useSelector(state => state.headFirstDelete)
  const { success: successDelete } = headFirstDelete



  useEffect(()=>{
    if (userInfo && userInfo.isAdmin) {
        dispatch(listHfs())
    } else {
        navigate('/login')
    }
  }, [dispatch, navigate, userInfo, successDelete])

  const deleteHandler = (id) => {

        if (window.confirm('Are you sure you want to delete this revision?'))
        {
            dispatch(deleteHeadFirst(id))
            
        }
    }
  return (
    <div>
      <LinkContainer className= '' to='/newheadfirstimage'><Button className='float-end' variant='success'>
            New Head First Image
                    </Button></LinkContainer>
       <h4>Head First Revisions</h4><hr></hr>
       
        {loading
        ? <Loader></Loader>
    : error? <Message variant='danger'>{error}</Message>
    :(
        <Table striped bordered hover responsive className='table-lg'>
            <thead>
                <th>Id</th>
                <th>Board</th>
                <th>Class</th>
                <th>Subject</th>
                <th>Topic</th>
                <th>Description</th>

                <th></th>
            </thead>

            <tbody>
                {headFirstImages.map(image =>(
                    <tr key = {image._id}>
                        <td>{image._id}</td>
                        <td>{image.board}</td>
                        <td>{image.classname}</td>
                        <td>{image.subject}</td>
                        <td>{image.topic}</td>
                        <td>{image.description}</td>
                        
                        <td>
                                            <LinkContainer to={`/admin/headfirstrevision/${image._id}/edit`}>
                                                <Button variant='light' className='btn-sm'>
                                                    <i className='fas fa-edit'></i>
                                                </Button>
                                            </LinkContainer>
                                            <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(image._id)}>
                                                <i className='fas fa-trash'></i>
                                            </Button>
                                        
                                           
                                        </td>
                                        

                    </tr>
                ))}
            </tbody>
        </Table>
    )
    }
    </div>
  )
}

export default HeadFirstImageStaffScreen
