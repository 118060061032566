export const HFIMAGE_CREATE_REQUEST = 'HFIMAGE_CREATE_REQUEST'
export const HFIMAGE_CREATE_SUCCESS = 'HFIMAGE_CREATE_SUCCESS'
export const HFIMAGE_CREATE_FAIL = 'HFIMAGE_CREATE_FAIL'
export const HFIMAGE_CREATE_RESET = 'HFIMAGE_CREATE_RESET'


export const HFIMAGE_UPDATE_REQUEST = 'HFIMAGE_UPDATE_REQUEST'
export const HFIMAGE_UPDATE_SUCCESS = 'HFIMAGE_UPDATE_SUCCESS'
export const HFIMAGE_UPDATE_FAIL = 'HFIMAGE_UPDATE_FAIL'
export const HFIMAGE_UPDATE_RESET = 'HFIMAGE_UPDATE_RESET'

export const HFIMAGE_LIST_REQUEST = 'HFIMAGE_LIST_REQUEST'
export const HFIMAGE_LIST_SUCCESS = 'HFIMAGE_LIST_SUCCESS'
export const HFIMAGE_LIST_FAIL = 'HFIMAGE_LIST_FAIL'

export const HFIMAGE_DETAILS_REQUEST = 'HFIMAGE_DETAILS_REQUEST'
export const HFIMAGE_DETAILS_SUCCESS = 'HFIMAGE_DETAILS_SUCCESS'
export const HFIMAGE_DETAILS_FAIL = 'HFIMAGE_DETAILS_FAIL'

export const HFIMAGE_DELETE_REQUEST = 'HFIMAGE_DELETE_REQUEST'
export const HFIMAGE_DELETE_SUCCESS = 'HFIMAGE_DELETE_SUCCESS'
export const HFIMAGE_DELETE_FAIL = 'HFIMAGE_DELETE_FAIL'

