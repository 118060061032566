export const  BOARD_LIST_REQUEST = 'BOARD_LIST_REQUEST'
export const  BOARD_LIST_SUCCESS = 'BOARD_LIST_SUCCESS'
export const  BOARD_LIST_FAIL = 'BOARD_LIST_FAIL'

export const  BOARD_DETAILS_REQUEST = 'BOARD_DETAILS_REQUEST'
export const  BOARD_DETAILS_SUCCESS = 'BOARD_DETAILS_SUCCESS'
export const  BOARD_DETAILS_FAIL = 'BOARD_DETAILS_FAIL'

export const BOARD_CREATE_REQUEST = 'BOARD_CREATE_REQUEST'
export const BOARD_CREATE_SUCCESS = 'BOARD_CREATE_SUCCESS'
export const BOARD_CREATE_FAIL = 'BOARD_CREATE_FAIL'
export const BOARD_CREATE_RESET = 'BOARD_CREATE_RESET'