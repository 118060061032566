import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import {Form, Button, Row, Col} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import Loader from '../components/Loader'
import { register } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import {useNavigate} from 'react-router-dom';
import Message from '../components/Message'
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';

function RegisterScreen(location, { onPasswordChange, minLength = 8 }) {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [strength, setStrength] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')



    const dispatch = useDispatch()
    const redirect = location.search ? location.search.split('=')[1] : '/'
  
    const navigate = useNavigate();

    const passwordRequirements = [
        { text: 'Minimum 8 characters, ', met: false },
        { text: 'lowercase letters (a-z), ', met: false },
        { text: ' uppercase letters (A-Z), ', met: false },
        { text: ' numbers (0-9) and ', met: false },
        { text: 'special characters (!@#$%^&*)', met: false },
      ];
  
  
    const userRegister = useSelector(state => state.userRegister)
    const { error, loading, userInfo } = userRegister
  
    useEffect(() => {

        const checkStrength = () => {
            const hasMinLength = password.length >= minLength;
            const hasLowercase = /[a-z]/.test(password);
            const hasUppercase = /[A-Z]/.test(password);
            const hasNumber = /[0-9]/.test(password);
            const hasSpecialChar = /[^a-zA-Z0-9\s]/.test(password);
      
            const metRequirements = [hasMinLength, hasLowercase, hasUppercase, hasNumber, hasSpecialChar];
            const numMet = metRequirements.filter(Boolean).length;
      
            setStrength(calculateStrength(numMet));
            passwordRequirements.forEach((req, index) => req.met = metRequirements[index]);
          };
      
          checkStrength();

          if (userInfo) {
              navigate(redirect)
          }
      }, [navigate, userInfo, redirect, password])

      const calculateStrength = (numMet) => {
        switch (numMet) {
          case 1:
            return 'Weak';
          case 2:
          case 3:
            return 'Medium';
          case 4:
          case 5:
            return 'Strong';
          default:
            return 'Invalid';
        }
      };

      const handleChange = (event) => {
        setPassword(event.target.value);
        onPasswordChange(event.target.value); // Pass password to parent
      };
    
      const passwordInputClasses = `password-input ${strength.toLowerCase()}`;
    
  
      const submitHandler = (e) => {
          e.preventDefault()
          if(password !== confirmPassword){
            setMessage("Password do not match")
          } else{
            dispatch(register(name, email, password))
          }
          
          
      }

  return (
    <FormContainer>
        <h3>Register</h3>
        
        <hr></hr>
        {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
                <Form.Label> Name</Form.Label>
                <Form.Control required type= 'name' placeholder='Enter name' Value={name} 
                onChange={(e)=> setName(e.target.value)}></Form.Control>

            </Form.Group>

            <Form.Group controlId='email'>
                <Form.Label> Email Address</Form.Label>
                <Form.Control required type= 'email' placeholder='Enter Email' Value={email} 
                onChange={(e)=> setEmail(e.target.value)}></Form.Control>

            </Form.Group>

            <Form.Group controlId='password'>
                <Form.Label> Password</Form.Label>
                <Form.Control required type= 'password' id="password" onChange={handleChange} placeholder='Enter Password' Value={password} 
                className={passwordInputClasses}></Form.Control>
                <div className="password-strength">
                         <Badge bg = 'secondary' className='my-2'>Strength: {strength}</Badge>
                       <Alert variant='light' className='my-2'>
                       A strong password has
                        {passwordRequirements.map((req, index) => (
                            <small key={index} className={req.met ? 'met' : ''}>
                            {req.text}
                            </small>
                        ))}
                        </Alert> 
                        
                    </div>
            </Form.Group>

            <Form.Group controlId='passwordConfirm'>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control required type= 'password' placeholder='Confirm Password' Value={confirmPassword} 
                onChange={(e)=> setConfirmPassword(e.target.value)}></Form.Control>
                
            </Form.Group>
            <br></br>
            <Button type = 'submit' variant='primary'>Register</Button>
        </Form>
        <Row className='py-3'>
                <Col>
                    Have an account? <Link
                        to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                        Sign In
                        </Link>
                </Col>
            </Row>
    </FormContainer>
  )
}

export default RegisterScreen