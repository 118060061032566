import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import {Form, Button, Row, Col} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {LinkContainer} from 'react-router-bootstrap'
import Loader from '../components/Loader'
import { createPlan } from '../actions/planActions'
import FormContainer from '../components/FormContainer'
import {useNavigate} from 'react-router-dom';
import Message from '../components/Message'
import{PLAN_CREATE_RESET} from '../constants/planConstants'

function CreatePlanScreen() {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [numberOfAssignments, setNumberOfAssignments] = useState('')
    
    const dispatch = useDispatch()

    const navigate = useNavigate();

    const planCreate = useSelector(state => state.planCreate)
    const {plan, error, success} = planCreate

    useEffect(()=>{
        if(success){
            navigate('/admin/planList')
            dispatch({type:PLAN_CREATE_RESET})
        }
      },[success, navigate])

    const submitHandler = (e) => {
        e.preventDefault()
        
        dispatch(createPlan(name, price, numberOfAssignments, description))  
    }
  return (
    <FormContainer>
        <h5>New Plan<LinkContainer to='/planList'>
                                <button type="button" className="btn-close float-end" aria-label="Close"></button>
                            </LinkContainer></h5>
        
        <hr></hr>
      
        <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
                <Form.Label> Name</Form.Label>
                <Form.Control required type= 'input' placeholder='Name' Value={name} 
                onChange={(e)=> setName(e.target.value)}></Form.Control>

            </Form.Group>

            <Form.Group controlId='price'>
                <Form.Label> Price </Form.Label>
                <Form.Control required type= 'input' placeholder='Enter price' Value={price} 
                onChange={(e)=> setPrice(e.target.value)}></Form.Control>

            </Form.Group>

            <Form.Group controlId='numberOfAssignments'>
                <Form.Label> Number of Assignments</Form.Label>
                <Form.Control required type= 'input' placeholder='Enter the number of assignments' Value={numberOfAssignments} 
                onChange={(e)=> setNumberOfAssignments(e.target.value)}></Form.Control>

            </Form.Group>


            <Form.Group controlId='description'>
                <Form.Label> Description</Form.Label>
                 <textarea class="form-control" required type= 'textarea' placeholder='Description' Value={description} 
                onChange={(e)=> setDescription(e.target.value)} rows="3"></textarea>

            </Form.Group>
            <br></br>
            <Button type = 'submit' variant='primary'>Create Plan</Button>
        </Form>
        
    
    </FormContainer>
  )
}

export default CreatePlanScreen
