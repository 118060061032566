import {
    PLAN_LIST_REQUEST, 
    PLAN_LIST_SUCCESS, 
    PLAN_LIST_FAIL,
    
    PLAN_CREATE_REQUEST, 
    PLAN_CREATE_SUCCESS, 
    PLAN_CREATE_FAIL,
    PLAN_CREATE_RESET,

    PLAN_PAY_REQUEST, 
    PLAN_PAY_SUCCESS, 
    PLAN_PAY_FAIL,
    PLAN_PAY_RESET,

    PLAN_DETAILS_REQUEST,
    PLAN_DETAILS_SUCCESS,
    PLAN_DETAILS_FAIL,
    PLAN_DETAILS_RESET,

    USER_PLAN_DETAILS_REQUEST,
    USER_PLAN_DETAILS_SUCCESS,
    USER_PLAN_DETAILS_FAIL,
    USER_PLAN_DETAILS_RESET,
    
} from '../constants/planConstants'


export const planCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PLAN_CREATE_REQUEST:
            return {
                loading: true
            }

        case PLAN_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                plan: action.payload
            }

        case PLAN_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case PLAN_CREATE_RESET:
            return {}


        default:
            return state
    }
}

export const planListReducer = (state = {plans:[]}, action)=>{
    switch(action.type){
        case PLAN_LIST_REQUEST:
            return {loading:true, plans:[]}

        case PLAN_LIST_SUCCESS:
            return {loading:false, plans:action.payload}

        case PLAN_LIST_FAIL:
            return {loading:false, error:action.payload}

        default: 
            return state
    }
}

export const planPayReducer = (state = {}, action) => {
    switch (action.type) {
        case PLAN_PAY_REQUEST:
            return {
                loading: true
            }

        case PLAN_PAY_SUCCESS:
            return {
                loading: false,
                success: true
            }

        case PLAN_PAY_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case PLAN_PAY_RESET:
                return {}

        default:
            return state
    }
}

export const planDetailsReducer = (state = { loading: true, plan:{} }, action) => {
    switch (action.type) {
        case PLAN_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case PLAN_DETAILS_SUCCESS:
            return {
                loading: false,
                plan: action.payload
            }

        case PLAN_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case PLAN_DETAILS_RESET:
                return { plan: {} }

        default:
            return state
    }
}

export const userPlanDetailsReducer = (state = { loading: true, userPlan:{} }, action) => {
    switch (action.type) {
        case USER_PLAN_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case USER_PLAN_DETAILS_SUCCESS:
            return {
                loading: false,
                userPlan: action.payload
            }

        case USER_PLAN_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case USER_PLAN_DETAILS_RESET:
                return { userPlan: {} }

        default:
            return state
    }
}