
import {
    SUBJECT_LIST_REQUEST, 
    SUBJECT_LIST_SUCCESS, 
    SUBJECT_LIST_FAIL,
    
    SUBJECT_CREATE_REQUEST, 
    SUBJECT_CREATE_SUCCESS, 
    SUBJECT_CREATE_FAIL,
    SUBJECT_CREATE_RESET,
    
} from '../constants/subjectConstants'

import axios from 'axios'


export const createSubject = (name, description) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SUBJECT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/subjects/add/`,
            {'name':name,  'description':description},
            config
        )

        dispatch({
            type: SUBJECT_CREATE_SUCCESS,
            payload: data
        })



    } catch (error) {
        dispatch({
            type: SUBJECT_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listSubjects = ()=> async (dispatch, getState)=>{
    try{
        
        dispatch({
            type: SUBJECT_LIST_REQUEST 
        })
        
       
        const {data} = await axios.get(
            '/api/subjects/'
        )


        dispatch({
            type: SUBJECT_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: SUBJECT_LIST_FAIL,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail 
            : error.message,
        })
    }
}