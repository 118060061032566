export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const ACTIVE_USER_LIST_REQUEST = 'ACTIVE_USER_LIST_REQUEST'
export const ACTIVE_USER_LIST_SUCCESS = 'ACTIVE_USER_LIST_SUCCESS'
export const ACTIVE_USER_LIST_FAIL = 'ACTIVE_USER_LIST_FAIL'
export const ACTIVE_USER_LIST_RESET = 'ACTIVE_USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const CREATE_PSWD_RESET_REQ_REQUEST = 'CREATE_PSWD_RESET_REQ_REQUEST'
export const CREATE_PSWD_RESET_REQ_SUCCESS = 'CREATE_PSWD_RESET_REQ_SUCCESS'
export const CREATE_PSWD_RESET_REQ_FAIL = 'CREATE_PSWD_RESET_REQ_FAIL'
export const CREATE_PSWD_RESET_REQ_RESET = 'CREATE_PSWD_RESET_REQ_RESET'

export const PSWD_RESET_REQ_LIST_REQUEST = 'PSWD_RESET_REQ_LIST_REQUEST'
export const PSWD_RESET_REQ_LIST_SUCCESS = 'PSWD_RESET_REQ_LIST_SUCCESS'
export const PSWD_RESET_REQ_LIST_FAIL = 'PSWD_RESET_REQ_LIST_FAIL'
export const PSWD_RESET_REQ_LIST_RESET = 'PSWD_RESET_REQ_LIST_RESET'