import {
    HFIMAGE_CREATE_REQUEST,
    HFIMAGE_CREATE_SUCCESS,
    HFIMAGE_CREATE_FAIL,
    HFIMAGE_CREATE_RESET,

    HFIMAGE_UPDATE_REQUEST,
    HFIMAGE_UPDATE_SUCCESS,
    HFIMAGE_UPDATE_FAIL,
    HFIMAGE_UPDATE_RESET,

    HFIMAGE_LIST_REQUEST, 
    HFIMAGE_LIST_SUCCESS, 
    HFIMAGE_LIST_FAIL,

    HFIMAGE_DETAILS_REQUEST,
    HFIMAGE_DETAILS_SUCCESS,
    HFIMAGE_DETAILS_FAIL,

    HFIMAGE_DELETE_REQUEST,
    HFIMAGE_DELETE_SUCCESS,
    HFIMAGE_DELETE_FAIL, 
} from '../constants/HeadFirstConstants'

export const hfCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case HFIMAGE_CREATE_REQUEST:
            return { loading: true }

        case HFIMAGE_CREATE_SUCCESS:
            return { loading: false, success: true, hfimage: action.payload }

        case HFIMAGE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case HFIMAGE_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const hfUpdateReducer = (state = { hfimage: {} }, action) => {
    switch (action.type) {
        case HFIMAGE_UPDATE_REQUEST:
            return { loading: true }

        case HFIMAGE_UPDATE_SUCCESS:
            return { loading: false, success: true, hfimage: action.payload }

        case HFIMAGE_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case HFIMAGE_UPDATE_RESET:
            return { hfimage: {} }

        default:
            return state
    }
}

export const hfListReducer = (state = {headFirstImages:[]}, action)=>{
    switch(action.type){
        case HFIMAGE_LIST_REQUEST:
            return {loading:true, headFirstImages:[]}

        case HFIMAGE_LIST_SUCCESS:
            return {loading:false, headFirstImages:action.payload}

        case HFIMAGE_LIST_FAIL:
            return {loading:false, error:action.payload}

        default: 
            return state
    }
}

export const hfDetailsReducer = (state = { hfr: { } }, action) => {
    switch (action.type) {
        case HFIMAGE_DETAILS_REQUEST:
            return { loading: true, ...state }

        case HFIMAGE_DETAILS_SUCCESS:
            return { loading: false, hfr: action.payload }

        case HFIMAGE_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const headFirstDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case HFIMAGE_DELETE_REQUEST:
            return { loading: true }

        case HFIMAGE_DELETE_SUCCESS:
            return { loading: false, success: true }

        case HFIMAGE_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}