import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import {LinkContainer} from 'react-router-bootstrap'
import {Form, Button, Row, Col} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import Loader from '../components/Loader'
import { getUserDetails, updateUser  } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import {useNavigate, useParams }from 'react-router-dom';
import Message from '../components/Message'
import {USER_UPDATE_RESET} from '../constants/userConstants'

function UserEditScreen(location) {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
   
    const [isAdmin, setAdmin] = useState(false)
    const {id} = useParams()
    
    const dispatch = useDispatch()
    
  
    const navigate = useNavigate();
  
  
    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails

    const userUpdate = useSelector(state => state.userUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = userUpdate
  
    useEffect(() => {

          if(successUpdate){
            dispatch({type:USER_UPDATE_RESET})
            navigate('/admin/userlist')
          } else{
            if(!user.name || user._id != id){   
                dispatch(getUserDetails(id))
              } else{
                setName(user.name)
                setEmail(user.email)
                setAdmin(user.isAdmin)
            
              }
          }
          
      }, [user, id, successUpdate, navigate])
  
      const submitHandler = (e) => {
          e.preventDefault()
          dispatch(updateUser({_id:user._id, name, email, isAdmin, password}))
      }

  return (
    <div>
    
    <FormContainer>
    <h5>Edit User 
        <LinkContainer to='/admin/userlist'>
             <button type="button" className="btn-close float-end" aria-label="Close"></button>
         </LinkContainer>
    </h5>
    <hr></hr>
    {loadingUpdate && <Loader></Loader>}
    {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
    {loading? <Loader></Loader> :error? <Message variant='danger'>{error}</Message> :
        ( <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
                <Form.Label> Name</Form.Label>
                <Form.Control  type= 'name' placeholder='Enter name' Value={name} 
                onChange={(e)=> setName(e.target.value)}></Form.Control>
    
            </Form.Group>
    
            <Form.Group controlId='email'>
                <Form.Label> Email Address</Form.Label>
                <Form.Control  type= 'email' placeholder='Enter Email' Value={email} 
                onChange={(e)=> setEmail(e.target.value)}></Form.Control>
    
            </Form.Group>
    
            <Form.Group controlId='isAdmin'>
                
                <Form.Check  type= 'checkbox' label='Is Admin' checked={isAdmin} 
                onChange={(e)=> setAdmin(e.target.checked)}></Form.Check>
    
            </Form.Group>
            <Form.Group controlId='password'>
                <Form.Label> Password</Form.Label>
                <Form.Control  type= 'password' placeholder='Enter Password' Value={password} 
                onChange={(e)=> setPassword(e.target.value)}></Form.Control>

            </Form.Group>

            <Form.Group controlId='passwordConfirm'>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control  type= 'password' placeholder='Confirm Password' Value={confirmPassword} 
                onChange={(e)=> setConfirmPassword(e.target.value)}></Form.Control>

            </Form.Group>
            <br></br>
            <Button type = 'submit' variant='primary'>Update</Button>
        </Form>)
    }
    
   
  
</FormContainer>
    
    </div>
   
  )
}

export default UserEditScreen