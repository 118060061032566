import React from 'react'
import {LinkContainer} from 'react-router-bootstrap'

function PrivacyPolicyScreen() {
  return (
    <div>
      <h5>Privacy Policy 
        <LinkContainer to='/'>
                                <button type="button" className="btn-close float-end" aria-label="Close"></button>
                            </LinkContainer>
                            
         </h5> 
         <hr></hr>
         <i>Effective Date: 28th May, 2024 -  &nbsp;</i>

        <small> weeval is committed to protecting the privacy 
    of our users. This Privacy Policy explains what information we collect, 
    how we use it, and how we keep it safe.</small>

<h6>Information We Collect:</h6>
<ul>
    <li>
        <small>
            <i>
            User Information: &nbsp;
            </i>
            When you create a weeval account, we may collect information such as your name, email address, and affiliation (student or teacher).
                
        </small>
    </li>
    <li>
        <small>
            <i>
                Usage Data: &nbsp;
            </i>
                We may collect data about how you use weeval, such as the types of 
                assignments you create or access, the time spent on the platform, and your interactions with features.
        </small> 
    </li>
    <li>
        <small><i>
            Non-Personally Identifiable Information:  &nbsp;
            </i>
            We may collect non-personally identifiable information such as 
            your device type, operating system, and browser information.

        </small>
    </li>
</ul>

<h6>
    How We Use Your Information:  &nbsp;
</h6>

<small>
    <ul>
        <li>
        We use your information to provide and improve the weeval service.
        </li>
        <li>
        We may use your information to send you important updates and communications about weeval.
        </li>
        <li>
        We may use your information to personalize your experience on weeval.
        </li>
        <li>
        We will never share your personal information with third parties without your consent, except as required by law.
        </li>
    </ul>

</small>

<h6>
    Data Retention:
</h6>

<small>
    We will retain your information for as long as your account is active or as needed 
    to provide you with the weeval service. We may also retain your information for legal or compliance purposes.
</small>
<h6>
    Your Choices:
</h6>

<small>
    You can access, update, or delete your personal information through 
    your account settings. You can also choose to opt out of receiving marketing communications from us.

</small>
<h6>
Security:
</h6>

<small>
We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or 
destruction. However, no internet transmission or electronic storage is ever completely secure.

</small>
<h6>Children's Privacy:</h6>

<small>
weeval is not directed towards children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us.

</small>
<h6>
Changes to this Privacy Policy:
</h6>
<small>
We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.

</small>

<h6>
Contact Us:
</h6>
<small>

If you have any questions about this Privacy Policy, please contact us at [Insert Email Address].
</small>

    </div>
  )
}

export default PrivacyPolicyScreen
