export const ASSIGNMENT_CREATE_REQUEST = 'ASSIGNMENT_CREATE_REQUEST'
export const ASSIGNMENT_CREATE_SUCCESS = 'ASSIGNMENT_CREATE_SUCCESS'
export const ASSIGNMENT_CREATE_FAIL = 'ASSIGNMENT_CREATE_FAIL'
export const ASSIGNMENT_CREATE_RESET = 'ASSIGNMENT_CREATE_RESET'

export const ASSIGNMENT_LIST_REQUEST = 'ASSIGNMENT_LIST_REQUEST'
export const ASSIGNMENT_LIST_SUCCESS = 'ASSIGNMENT_LIST_SUCCESS'
export const ASSIGNMENT_LIST_FAIL = 'ASSIGNMENT_LIST_FAIL'

export const ASSIGNMENT_LIST_MY_REQUEST = 'ASSIGNMENT_LIST_MY_REQUEST'
export const ASSIGNMENT_LIST_MY_SUCCESS = 'ASSIGNMENT_LIST_MY_SUCCESS'
export const ASSIGNMENT_LIST_MY_FAIL = 'ASSIGNMENT_LIST_MY_FAIL'
export const ASSIGNMENT_LIST_MY_RESET = 'ASSIGNMENT_LIST_MY_RESET'

export const ASSIGNMENT_DETAILS_REQUEST = 'ASSIGNMENT_DETAILS_REQUEST'
export const ASSIGNMENT_DETAILS_SUCCESS = 'ASSIGNMENT_DETAILS_SUCCESS'
export const ASSIGNMENT_DETAILS_FAIL = 'ASSIGNMENT_DETAILS_FAIL'
export const ASSIGNMENT_DETAILS_RESET = 'ASSIGNMENT_DETAILS_RESET'

export const ASSIGNMENT_RESOURCE_DETAILS_REQUEST = 'ASSIGNMENT_RESOURCE_DETAILS_REQUEST'
export const ASSIGNMENT_RESOURCE_DETAILS_SUCCESS = 'ASSIGNMENT_RESOURCE_DETAILS_SUCCESS'
export const ASSIGNMENT_RESOURCE_DETAILS_FAIL = 'ASSIGNMENT_RESOURCE_DETAILS_FAIL'
export const ASSIGNMENT_RESOURCE_DETAILS_RESET = 'ASSIGNMENT_RESOURCE_DETAILS_RESET'

export const ASSIGNMENT_DELETE_REQUEST = 'ASSIGNMENT_DELETE_REQUEST'
export const ASSIGNMENT_DELETE_SUCCESS = 'ASSIGNMENT_DELETE_SUCCESS'
export const ASSIGNMENT_DELETE_FAIL = 'ASSIGNMENT_DELETE_FAIL'
