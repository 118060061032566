import React,  {useState, useEffect} from 'react'
import { Button, Row, Col, ListGroup, Image, Card , Table} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {LinkContainer} from 'react-router-bootstrap'
import { listSubjects } from '../actions/subjectActions'
import { listPswdResetReqs } from '../actions/userActions'
import { listClassnames, deleteClassname } from '../actions/classnameActions'
import { listBoards } from '../actions/boardActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {useNavigate, useParams }from 'react-router-dom';

function AdminToolsScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const subjectList = useSelector(state => state.subjectList)
  const {loading, error, subjects} = subjectList

  const boardList = useSelector(state => state.boardList)
  const {loading:boardLoading, error:boardError, boards} = boardList

  const classnameList = useSelector(state => state.classnameList)
  const {loading:classnamesLoading, error:classnamesError, classnames} = classnameList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const classnameDelete = useSelector(state => state.classnameDelete)
  const { success: successDelete } = classnameDelete

  const pswdResetReqList = useSelector(state => state.pswdResetReqList)
  const {loading:pswdResetReqListLoading, error:pswdResetReqListError, reqs} = pswdResetReqList


  useEffect(()=>{
    if (userInfo && userInfo.isAdmin) {
        dispatch(listSubjects())
        dispatch(listClassnames())
        dispatch(listBoards())
        dispatch(listPswdResetReqs())
    } else {
        navigate('/login')
    }
  }, [dispatch, navigate, userInfo, successDelete])

  const deleteHandler = (id) => {

        if (window.confirm('Are you sure you want to delete this revision?'))
        {
            dispatch(deleteClassname(id))
            
        }
    }

  return (
    <div>
       <Row>
            <Col md={8}>
            <h4>Password Reset Requests</h4>
            {pswdResetReqListLoading
                    ? <Loader></Loader>
                : error? <Message variant='danger'>{pswdResetReqListError}</Message>
                :(
                    <Table striped bordered hover responsive className='table-lg'>
                        <thead>
                            <th>Email</th>
                            <th>Temporary password</th>
                            <th>Reset?</th>
                            <th></th>
                        </thead>

                        <tbody>
                            {reqs.map(req =>(
                               
                                    <tr key = {req._id} >
                                    <td>{req.email}</td>
                                    <td>{req.temp_password}</td>
                                    <td>{req.is_fullfilled ?
                        (
                            <i className='fas fa-check' style={{color:'green'}}></i>
                        ):(
                            <i className='fas fa-check' style={{color:'red'}}></i>
                        )}</td>
                                </tr>
                             
                            
                            ))}
                        </tbody>
                    </Table>
                    )
                    }

            </Col>
            <Col md={4}>
            <h4>Boards&nbsp;&nbsp;<LinkContainer className= '' to='/newboard'><Button className='' variant='success'>
                    New Board
                                </Button></LinkContainer></h4><hr></hr>
                    {classnamesLoading
                    ? <Loader></Loader>
                : error? <Message variant='danger'>{classnamesError}</Message>
                :(
                    <Table striped bordered hover responsive className='table-lg'>
                        <thead>
                            <th>Name</th>
                            <th>Description</th>
                            
                            <th></th>
                        </thead>

                        <tbody>
                            {boards.map(board =>(
                               
                                    <tr key = {board._id} >
                                    <td>{board.name}</td>
                                    <td>{board.description}</td>
                                </tr>
                             
                            
                            ))}
                        </tbody>
                    </Table>
                    )
                    }
                <h4>Classnames&nbsp;&nbsp;<LinkContainer className= '' to='/newclassname'><Button className='' variant='success'>
                    New Classname
                                </Button></LinkContainer></h4><hr></hr>
                    {classnamesLoading
                    ? <Loader></Loader>
                : error? <Message variant='danger'>{classnamesError}</Message>
                :(
                    <Table striped bordered hover responsive className='table-lg'>
                        <thead>
                            <th>Name</th>
                            <th>Description</th>
                            
                            <th></th>
                        </thead>

                        <tbody>
                            {classnames.map(classname =>(
                               
                                    <tr key = {classname._id} >
                                    <td>{classname.name}</td>
                                    <td>{classname.description}</td>
                                    <td>
                                    <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(classname._id)}>
                                                <i className='fas fa-trash'></i>
                                            </Button>

                                    </td>
                                </tr>
                              
                            
                            ))}
                        </tbody>
                    </Table>
                    )
                    }

<h4>Subjects&nbsp;&nbsp;<LinkContainer className= '' to='/newsubject'><Button className='' variant='success'>
                    New Subject
                                </Button></LinkContainer></h4><hr></hr>
                    {loading
                    ? <Loader></Loader>
                : error? <Message variant='danger'>{error}</Message>
                :(
                    <Table striped bordered hover responsive className='table-lg'>
                        <thead>
                            <th>Name</th>
                            <th>Description</th>
                            
                            <th></th>
                        </thead>

                        <tbody>
                            {subjects.map(subject =>(
                               
                                    <tr key = {subject._id} >
                                    <td>{subject.name}</td>
                                    <td>{subject.description}</td>
                                </tr>
                              
                            
                            ))}
                        </tbody>
                    </Table>
                    )
                    }
            </Col>

       </Row>
                        
    </div>
  )
}

export default AdminToolsScreen
