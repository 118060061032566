import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import {Form, Button, Row, Col} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import Loader from '../components/Loader'
import { login } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import {useNavigate} from 'react-router-dom';
import Message from '../components/Message'

function LoginScreen(location) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const redirect = location.search ? location.search.split('=')[1] : '/'

  const navigate = useNavigate();


  const userLogin = useSelector(state => state.userLogin)
  const { error, loading, userInfo } = userLogin

  useEffect(() => {
        if (userInfo && !userInfo.isAdmin) {
            navigate('/userscreen')
        } else if(userInfo && userInfo.isAdmin){
            navigate('/admin/assignmentlist')
        }
    }, [navigate, userInfo, '/userscreen'])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password))
        
    }
    return (
    <FormContainer>
        <h3>Sign In</h3>
        
        <hr></hr>
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
            <Form.Group controlId='email'>
                <Form.Label> Email Address</Form.Label>
                <Form.Control type= 'email' placeholder='Enter Email' Value={email} 
                onChange={(e)=> setEmail(e.target.value)}></Form.Control>

            </Form.Group>

            <Form.Group controlId='password'>
                <Form.Label> Password</Form.Label>
                <Form.Control type= 'password' placeholder='Enter Password' Value={password} 
                onChange={(e)=> setPassword(e.target.value)}></Form.Control>

            </Form.Group>
            <br></br>
            <Button type = 'submit' variant='primary'>Sign In</Button>
        </Form>
        <Row className='py-3'>
                <Col>
                    <div>

                    New customer? <Link
                        to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                        Register
                        </Link>
                    </div>
                    <div>
                    Forgot password? <Link
                        to='/passwordresetrequest'>
                        Request for passowrd reset
                        </Link>

                    </div>
                       
                </Col>
         </Row>
    </FormContainer>
  )
}

export default LoginScreen