import React from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom'


function Footer() {
  return (
    <footer className='footer-mainpage pb-3'>
        <Container >
            <Row>
                <Col className='text-center py-1'>
                 Copyright &copy; we<b>e</b>val &nbsp; | &nbsp; Hiranya Varne Ventures
                </Col>
            </Row> 
            <Row>
                <Col className='text-center py-1'>
                  
                  <Link to='/ternsandconditions'>
                    Terms and Conditions
                  </Link> &nbsp; | &nbsp;
                  <Link to='/privacypolicy'>
                    Privacy Policy
                  </Link> &nbsp; | &nbsp;
                  <Link to='/returnpolicy'>
                    Return Policy
                  </Link>
                </Col>
            </Row> 
        </Container>
    </footer>
    
  )
}

export default Footer