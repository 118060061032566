import React, { useEffect} from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import {Table, Button, Row, Col, Card} from 'react-bootstrap'

import {useDispatch, useSelector} from 'react-redux'

import Loader from '../components/Loader'
import { listPlans, getUserPlanDetails } from '../actions/planActions'
import {useNavigate} from 'react-router-dom';
import Message from '../components/Message'
import { Link } from 'react-router-dom'

function PlanListScreen() {

    const dispatch = useDispatch()
    const navigate = useNavigate();
  
    const planList = useSelector(state => state.planList)
    const {loading, error, plans} = planList
  
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userPlanDetails = useSelector(state => state.userPlanDetails)
    const { userPlan } = userPlanDetails

    useEffect(()=>{
      
          dispatch(listPlans())
          dispatch(getUserPlanDetails())
      
    }, [dispatch])
  return (
    <div>
       <h4>Plans&nbsp; &nbsp;
        {
                    userInfo && userInfo.isAdmin && (
                    
                      <LinkContainer className= '' to='/newplan'><Button className='' variant='info'>
                      New Plan
                              </Button></LinkContainer>

                    )
                 }
        </h4><hr></hr>
        {loading
        ? <Loader></Loader>
    : error? <Message variant='danger'>{error}</Message>
    :(
        <div>
           <Row>
           {
                                  userPlan &&  userInfo && !userInfo.isAdmin && (
                                    <Message variant='secondary'>You have an active <Link
                                    to='/profile'>
                                    plan
                                    </Link>. You can buy one of the below plans once the current plan is expired.</Message>

                                  )
                              }
           {plans.map(plan =>(
                     <Col  md={4}>
                         <Card className='py-2 px-2' key = {plan._id} >
                         
                            <h6 className=''>{plan.name}</h6><small className='float-end'>Rs.{plan.price}</small>
                            <hr></hr>
                            
                            <small><b>Number of question papers: </b>{plan.numberOfAssignments}</small>
                           
                            <small>{plan.description}</small>
                            {
                                  !userPlan &&  userInfo && !userInfo.isAdmin && (
                                    <LinkContainer className= '' to={`/paymentScreen/${plan._id}`}>
                                      <span className='form-control text-center btn btn-success mt-2'>Buy</span>
                                    </LinkContainer>
                                    

                                  )
                              }

                          
                           
                            
                      </Card>
                      


                     </Col>
                   
                ))}

           </Row>
            
               
          
        </div>
    )
    }
    
    </div>
  )
}

export default PlanListScreen
