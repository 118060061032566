import React from 'react'
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import FormContainer from '../components/FormContainer'
import {LinkContainer} from 'react-router-bootstrap'
import {useState, useEffect} from 'react'
import {Form, Button, Row, Col} from 'react-bootstrap'
import { createHeadFirst } from '../actions/HeadFirstActions'
import{HFIMAGE_CREATE_RESET} from '../constants/HeadFirstConstants'

import { listSubjects } from '../actions/subjectActions'
import { listClassnames } from '../actions/classnameActions'
import { listBoards } from '../actions/boardActions'

function CreateHeadFirstRevisionScreen() {
  const [board, setSelectedBoard] = useState('');
  const [classname, setSelectedClass] = useState('');
  const [subject, setSelectedSubject] = useState('');
  
  const [topic, setTopic] = useState('')
  const [description, setDescription] = useState('')
  const dispatch = useDispatch()

  const navigate = useNavigate();

  const hfCreate = useSelector(state => state.hfCreate)
  const {hfimage, error, success} = hfCreate

  const subjectList = useSelector(state => state.subjectList)
  const {loading, error:subjectListError, subjects} = subjectList

  const boardList = useSelector(state => state.boardList)
  const {loading:boardLoading, error:boardError, boards} = boardList

  const classnameList = useSelector(state => state.classnameList)
  const {loading:classnamesLoading, error:classnamesError, classnames} = classnameList

  useEffect(()=>{
    dispatch(listSubjects())
    dispatch(listClassnames())
    dispatch(listBoards())

    if(success){
          navigate('/admin/headfirstrevisions')
          dispatch({type:HFIMAGE_CREATE_RESET})
    }
  },[success, navigate])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(createHeadFirst(board, classname, subject,topic, description))  
      
  }
  return (
    <div>
      <FormContainer>
        <h5>New Head First Revision 
            <LinkContainer to='/admin/headfirstrevisions'>
                <button type="button" className="btn-close float-end" aria-label="Close"></button>
            </LinkContainer>
        </h5>
        <hr></hr>
        <Form onSubmit={submitHandler}>
           <Form.Group controlId="formBoard">
              <Form.Label>Board</Form.Label>
              <Form.Control 
                as="select" 
                value={board} 
                onChange={(e) => setSelectedBoard(e.target.value)} 
                required
              >
                <option value="">Select Board</option>
                {boards.map(board => (
                  <option key={board.id} value={board.id}>{board.name}</option>
                ))}
            </Form.Control>
            </Form.Group>

            <Form.Group controlId="formClass">
              <Form.Label>Class</Form.Label>
              <Form.Control 
                as="select" 
                value={classname} 
                onChange={(e) => setSelectedClass(e.target.value)} 
                required
              >
                <option value="">Select Class</option>
                {classnames.map(cls => (
                  <option key={cls.id} value={cls.id}>{cls.name}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control 
                as="select" 
                value={subject} 
                onChange={(e) => setSelectedSubject(e.target.value)} 
                required
              >
                <option value="">Select Subject</option>
                {subjects.map(subject => (
                  <option key={subject.id} value={subject.id}>{subject.name}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='topic'>
                <Form.Label> Topic</Form.Label>
                <Form.Control required type= 'input' placeholder='Enter Topic' Value={topic} 
                onChange={(e)=> setTopic(e.target.value)}></Form.Control>

            </Form.Group>

            <Form.Group controlId='classname'>
                <Form.Label> Description</Form.Label>
                 <textarea class="form-control" required type= 'textarea' placeholder='Description' Value={description} 
                onChange={(e)=> setDescription(e.target.value)} rows="3"></textarea>

            </Form.Group>
            <br></br>
            <Button type = 'submit' variant='primary'>Create Revision</Button>
        </Form>
    </FormContainer>
    </div>
  )
}

export default CreateHeadFirstRevisionScreen
