import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import {Form, Button, Row, Col} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import Loader from '../components/Loader'
import { createPswdResetReq } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import Message from '../components/Message'
import {useNavigate, useParams }from 'react-router-dom';


function PasswordResetRequestScreen() {

    const [email, setEmail] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const pswdResetReqCreate = useSelector((state) => state.pswdResetReqCreate);
    const { loading, error, success } = pswdResetReqCreate;

   
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createPswdResetReq(email))
       
        navigate('/login');
        
    }
  return (
    <FormContainer>
        <h3>Password Reset Request</h3>
        
        <hr></hr>
      <Message variant='secondary'>An email with temporary password will be sent to your email address within 6 hours of request. You can login and reset your password by navigating to your profile.
      </Message>
        <Form onSubmit={submitHandler}>
            <Form.Group controlId='email'>
                <Form.Label> Email Address</Form.Label>
                <Form.Control required type= 'email' placeholder='Enter Email' Value={email} 
                onChange={(e)=> setEmail(e.target.value)}></Form.Control>

            </Form.Group>

            <br></br>
            <Button type = 'submit' variant='primary'>Submit</Button>
        </Form>
       
    </FormContainer>
  )
}

export default PasswordResetRequestScreen
