import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getPlanDetails, payPlan } from '../actions/planActions'
import {useNavigate, useParams }from 'react-router-dom';
import { Button, Form, Row, Col, ListGroup } from 'react-bootstrap'
import axios from 'axios'

function PostPayScreen() {
    const navigate = useNavigate()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const dispatch = useDispatch()

    const getCSRFToken = () => {
        let csrfToken = null;
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.startsWith('csrftoken=')) {
            csrfToken = cookie.substring('csrftoken='.length);
            break;
          }
        }
        return csrfToken;
      };

    useEffect(()=>{
        const fetchData = async () => {
            if (!userInfo) {
              navigate('/login');
            } else {
                const csrfToken = getCSRFToken();
                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`,
                        'X-CSRFToken': csrfToken,
                    }
                }
      
              try {
                const response = await axios.post(
                  '/api/payments/status',
                  {},
                  config
                );
                // Handle response if needed
                console.log(response.data);
              } catch (error) {
                console.error('Error:', error);
              }
            }
          };
        
    fetchData();
  
      },[dispatch, navigate, userInfo]);


  return (
    <div>
      
    </div>
  )
}

export default PostPayScreen
