import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {LinkContainer} from 'react-router-bootstrap'
import {useDispatch, useSelector} from  'react-redux'
import { NavDropdown } from 'react-bootstrap';


import {useNavigate} from 'react-router-dom';
import {Form, Button, Row, Col} from 'react-bootstrap'

import { logout } from '../actions/userActions'

function Header(location) {

  const useLogin = useSelector(state => state.userLogin)
  const {userInfo} = useLogin
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const redirect = location.search ? location.search.split('=')[5] : '/'
  const logoutHandler = () => {
      dispatch(logout())
      navigate(redirect)
  }

  return (
    <div>
        <header >
            <Navbar expand="lg" className="bg-body-tertiary">
                  <Container>
                    <LinkContainer  to='/'>
                        <Navbar.Brand className= ''>WE<b>E</b>VAL</Navbar.Brand>
                    </LinkContainer>
                  
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                       
                          {
                          userInfo ?(
                            <NavDropdown title =<span className='navbar-user-name'><i className="fa-solid fa-user"></i></span>  id = 'username'> 
                            <LinkContainer to='/profile'>
                            <NavDropdown.Item className='user-dropdown-profile'>
                               {userInfo.name}
                               <div><small>{userInfo.username}</small></div>
                            
                            </NavDropdown.Item>
                             
                            
                          </LinkContainer>
                
                            {!userInfo.isAdmin && (
                  <LinkContainer to='/userscreen'>
                  <NavDropdown.Item> Assignments</NavDropdown.Item>
                  </LinkContainer>
                )
                }

            

                {userInfo && userInfo.isAdmin && (
                    
                  <LinkContainer to='/admin/admintoolsscreen'>
                  <NavDropdown.Item> AdminTools</NavDropdown.Item>
                  </LinkContainer>
                  

                  )
                
                 }
                 
              
                 
                  {userInfo && userInfo.isAdmin && (
                    
                    <LinkContainer to='/admin/userlist'>
                      <NavDropdown.Item> Users</NavDropdown.Item>
                    </LinkContainer>

                    

                    )
                  
                   }
                   {userInfo &&  userInfo.isAdmin &&(
                    
                    <LinkContainer to='/admin/headfirstdisplay'>
                      <NavDropdown.Item> Head First Revisions</NavDropdown.Item>
                    </LinkContainer>

                    

                    )
                  
                   }

                  
                    
                   {userInfo && userInfo.isAdmin && (
                    
                    <LinkContainer to='/admin/headfirstrevisions'>
                      <NavDropdown.Item> Head First Revisions Staff</NavDropdown.Item>
                    </LinkContainer>

                    

                    )
                  
                   }
                     

                   {
                    userInfo && userInfo.isAdmin && (
                    
                    <LinkContainer to='/admin/assignmentlist'>
                      <NavDropdown.Item> Assignments</NavDropdown.Item>
                    </LinkContainer>

                    )
                 }
                  <NavDropdown.Item onClick={logoutHandler}> Logout</NavDropdown.Item>
              </NavDropdown>

                          )
                          : (
                            <LinkContainer  variant='primary' to='/login'>
                            <Nav.Link >Login <i className="fa-solid fa-user"></i></Nav.Link>
                       </LinkContainer>
                          )
                          }
                        </Nav>
                          
                        </Navbar.Collapse>
                        {
                    userInfo  && (
                    
                      <LinkContainer to='/planList'>

                      <Nav.Link><button type="button" class="btn btn-warning">Plans</button> </Nav.Link>
                 </LinkContainer>

                    )
                 }
                       
                       
                </Container>
                
            </Navbar>
            
        </header>
    </div>
  )
}

export default Header