import {
    ASSIGNMENT_LIST_REQUEST, 
    ASSIGNMENT_LIST_SUCCESS, 
    ASSIGNMENT_LIST_FAIL,
    
    ASSIGNMENT_CREATE_REQUEST, 
    ASSIGNMENT_CREATE_SUCCESS, 
    ASSIGNMENT_CREATE_FAIL,
    ASSIGNMENT_CREATE_RESET,
    
    ASSIGNMENT_LIST_MY_REQUEST, 
    ASSIGNMENT_LIST_MY_SUCCESS, 
    ASSIGNMENT_LIST_MY_FAIL,
    ASSIGNMENT_LIST_MY_RESET,

    ASSIGNMENT_DETAILS_REQUEST,
    ASSIGNMENT_DETAILS_SUCCESS,
    ASSIGNMENT_DETAILS_FAIL,
    ASSIGNMENT_DETAILS_RESET,

    ASSIGNMENT_DELETE_REQUEST,
    ASSIGNMENT_DELETE_SUCCESS,
    ASSIGNMENT_DELETE_FAIL, 


    ASSIGNMENT_RESOURCE_DETAILS_REQUEST,
    ASSIGNMENT_RESOURCE_DETAILS_SUCCESS,
    ASSIGNMENT_RESOURCE_DETAILS_FAIL,
    ASSIGNMENT_RESOURCE_DETAILS_RESET,

} from '../constants/assignmentConstants'


export const assignmentCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ASSIGNMENT_CREATE_REQUEST:
            return {
                loading: true
            }

        case ASSIGNMENT_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                assignment: action.payload
            }

        case ASSIGNMENT_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case ASSIGNMENT_CREATE_RESET:
            return {}


        default:
            return state
    }
}

export const assignmentDetailsReducer = (state = { loading: true, assignment:{} }, action) => {
    switch (action.type) {
        case ASSIGNMENT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case ASSIGNMENT_DETAILS_SUCCESS:
            return {
                loading: false,
                assignment: action.payload
            }

        case ASSIGNMENT_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case ASSIGNMENT_DETAILS_RESET:
                return { assignment: {} }

        default:
            return state
    }
}

export const assignmentListReducer = (state = {assignments:[]}, action)=>{
    switch(action.type){
        case ASSIGNMENT_LIST_REQUEST:
            return {loading:true, assignments:[]}

        case ASSIGNMENT_LIST_SUCCESS:
            return {loading:false, assignments:action.payload}

        case ASSIGNMENT_LIST_FAIL:
            return {loading:false, error:action.payload}

        default: 
            return state
    }
}

export const assignmentListMyReducer = (state = {assignments:[]}, action)=>{
    switch(action.type){
        case ASSIGNMENT_LIST_MY_REQUEST:
            return {loading:true}

        case ASSIGNMENT_LIST_MY_SUCCESS:
            return {loading:false, assignments:action.payload}

        case ASSIGNMENT_LIST_MY_FAIL:
            return {loading:false, error:action.payload}

        case ASSIGNMENT_LIST_MY_RESET:
                return {
                    assignments:[]
                }
    

        default: 
            return state
    }
}

export const assignmentResourcDetailsReducer = (state = { loading: true, assignmentResource:{} }, action) => {
    switch (action.type) {
        case ASSIGNMENT_RESOURCE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case ASSIGNMENT_RESOURCE_DETAILS_SUCCESS:
            return {
                loading: false,
                assignmentResource: action.payload
            }

        case ASSIGNMENT_RESOURCE_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case ASSIGNMENT_RESOURCE_DETAILS_RESET:
                return { assignmentResource: {} }

        default:
            return state
    }
}

export const assignmentDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ASSIGNMENT_DELETE_REQUEST:
            return { loading: true }

        case ASSIGNMENT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case ASSIGNMENT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}