import React, {useState, useEffect} from 'react'
import {Table, Button, Row, Col} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import Loader from '../components/Loader'
import { listAssignments, deleteAssignment } from '../actions/assignmentActions'
import {useNavigate} from 'react-router-dom';
import Message from '../components/Message'
import Badge from 'react-bootstrap/Badge';

function AssignmentListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const assignmentList = useSelector(state => state.assignmentList)
  const {loading, error, assignments} = assignmentList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const assignmentDelete = useSelector(state => state.assignmentDelete)
  const { success: successDelete } = assignmentDelete

  const isNew = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const differenceInHours = (now - createdDate) / (1000 * 60 * 60);
    return differenceInHours < 24;
  };

  useEffect(()=>{
    if (userInfo && userInfo.isAdmin) {
        dispatch(listAssignments())
    } else {
        navigate('/login')
    }
  }, [dispatch, navigate, userInfo, successDelete])

  const deleteHandler = (id) => {

    if (window.confirm('Are you sure you want to delete this user?'))
    {
        dispatch(deleteAssignment(id))
        
    }
}

const expand = (id, userId) => {

    navigate(`/admin/assignments/${id}/${userId}`)
        
  
}

 
  return (
    <div>
       <h4>Assignments</h4><hr></hr>
        {loading
        ? <Loader></Loader>
    : error? <Message variant='danger'>{error}</Message>
    :(
        <Table striped bordered hover responsive className='table-lg'>
            <thead>
                <th></th>
                <th>Student Name</th>
                <th>Topic</th>
                <th>Details</th>
                <th>Request Date</th>
                <th></th>
            </thead>

            <tbody>
                {assignments.map(assignment =>(
                    
                         <tr key = {assignment._id} >
                        <td> 

                        <Button variant='primary' className='btn-sm' onClick={() => expand(assignment._id, assignment.user)}>
                        <i class="fa-solid fa-maximize"></i>
                                            </Button>
                        </td>
                        <td>{assignment.student_name}</td>
                        <td>{assignment.topic}&nbsp; &nbsp;{isNew(assignment.createdAt) && <Badge bg="warning" >New</Badge>}</td>
                        <td>{assignment.board} &nbsp; {assignment.className} &nbsp; {assignment.subject} &nbsp; {assignment.description} </td>
                        <td>{assignment.createdAt.substring(0, 10)}</td>
                        <td>{assignment.evaluation_image_submitted? <i class="fas fa-check-circle"></i>:(assignment.response_image_submitted?
                        <i class="far fa-check-circle"></i>:(assignment.questions_image_submitted?
                            <i class="fas fa-check-double"></i>:(<i class="fa fa-check" ></i>))) }

                                &nbsp; &nbsp;<Button variant='danger' className='btn-sm' onClick={() => deleteHandler(assignment._id)}>
                                                <i className='fas fa-trash'></i>
                                            </Button>
                        </td>
                    </tr>

                    
                   
                ))}
            </tbody>
        </Table>
    )
    }
    
    </div>
  )
}

export default AssignmentListScreen