export const CLASSNAME_CREATE_REQUEST = 'CLASSNAME_CREATE_REQUEST'
export const CLASSNAME_CREATE_SUCCESS = 'CLASSNAME_CREATE_SUCCESS'
export const CLASSNAME_CREATE_FAIL = 'CLASSNAME_CREATE_FAIL'
export const CLASSNAME_CREATE_RESET = 'CLASSNAME_CREATE_RESET'

export const CLASSNAME_LIST_REQUEST = 'CLASSNAME_LIST_REQUEST'
export const CLASSNAME_LIST_SUCCESS = 'CLASSNAME_LIST_SUCCESS'
export const CLASSNAME_LIST_FAIL = 'CLASSNAME_LIST_FAIL'

export const CLASSNAME_DELETE_REQUEST = 'CLASSNAME_DELETE_REQUEST'
export const CLASSNAME_DELETE_SUCCESS = 'CLASSNAME_DELETE_SUCCESS'
export const CLASSNAME_DELETE_FAIL = 'CLASSNAME_DELETE_FAIL'