export const PLAN_CREATE_REQUEST = 'PLAN_CREATE_REQUEST'
export const PLAN_CREATE_SUCCESS = 'PLAN_CREATE_SUCCESS'
export const PLAN_CREATE_FAIL = 'PLAN_CREATE_FAIL'
export const PLAN_CREATE_RESET = 'PLAN_CREATE_RESET'

export const PLAN_LIST_REQUEST = 'PLAN_LIST_REQUEST'
export const PLAN_LIST_SUCCESS = 'PLAN_LIST_SUCCESS'
export const PLAN_LIST_FAIL = 'PLAN_LIST_FAIL'

export const PLAN_DETAILS_REQUEST = 'PLAN_DETAILS_REQUEST'
export const PLAN_DETAILS_SUCCESS = 'PLAN_DETAILS_SUCCESS'
export const PLAN_DETAILS_FAIL = 'PLAN_DETAILS_FAIL'
export const PLAN_DETAILS_RESET = 'PLAN_DETAILS_RESET'

export const PLAN_PAY_REQUEST = 'PLAN_PAY_REQUEST'
export const PLAN_PAY_SUCCESS = 'PLAN_PAY_SUCCESS'
export const PLAN_PAY_FAIL = 'PLAN_PAY_FAIL'
export const PLAN_PAY_RESET = 'PLAN_PAY_RESET'

export const USER_PLAN_DETAILS_REQUEST = 'USER_PLAN_DETAILS_REQUEST'
export const USER_PLAN_DETAILS_SUCCESS = 'USER_PLAN_DETAILS_SUCCESS'
export const USER_PLAN_DETAILS_FAIL = 'USER_PLAN_DETAILS_FAIL'
export const USER_PLAN_DETAILS_RESET = 'USER_PLAN_DETAILS_RESET'