import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getPlanDetails } from '../actions/planActions'
import {useNavigate, useParams }from 'react-router-dom';
import { Button, Form, Row, Col, ListGroup } from 'react-bootstrap'
import Loader from '../components/Loader';
import axios from 'axios'


function PaymentScreen() {
  const {planId} = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const planPay = useSelector(state => state.planPay)
  const {loading: loadingPay, success: successPay} = planPay

  const planDetails = useSelector(state => state.planDetails)
  const { plan, error, loading } = planDetails

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  useEffect(()=>{
      if (!userInfo) {
        navigate('/login')
      }

      if ( plan._id !== Number(planId)) {
        dispatch(getPlanDetails(planId))
      } else{
       
      }
      
     

    }, [dispatch, successPay, plan , userInfo])


    const handlePayment = async (e) => {
        e.preventDefault()
    
        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`
          }
      }
        
       
        try {
          const response = await axios.post(
              `/api/payments/pay/`,
              {'plan':planId},
              config
          );
  
          console.log(response.data);
          const tokenUrl = response.data['redirect_url']
          
          window.PhonePeCheckout.transact({ tokenUrl });
      } catch (error) {
          console.error('Error:', error);
      }

      }

  return (
    <div>
      <Row>
        <Col md={6}>

                <ListGroup>
                    <ListGroup.Item>
                        <h5>Make Payment</h5>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        {loadingPay && <Loader></Loader>}
                       
                        <Form onSubmit={handlePayment}>
                            <Form.Group controlId='planDetails'>
                                              <h6 className=''>{plan.name}</h6>
                              <small className=''>Rs.{plan.price}</small>
                              <hr></hr>
                              
                              
                                <small>{plan.description}</small>

                            </Form.Group>

                            
                            <br></br>
                            <Button type = 'submit' variant='primary'>Pay now</Button>
                        </Form>

                    </ListGroup.Item>

            </ListGroup>
        </Col>
      </Row>
      
    </div>
  )
}

export default PaymentScreen
