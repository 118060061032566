import {
    PLAN_LIST_REQUEST, 
    PLAN_LIST_SUCCESS, 
    PLAN_LIST_FAIL,

    PLAN_CREATE_REQUEST, 
    PLAN_CREATE_SUCCESS, 
    PLAN_CREATE_FAIL,
    PLAN_CREATE_RESET,

    PLAN_PAY_REQUEST, 
    PLAN_PAY_SUCCESS, 
    PLAN_PAY_FAIL,
    PLAN_PAY_RESET,

    PLAN_DETAILS_REQUEST,
    PLAN_DETAILS_SUCCESS,
    PLAN_DETAILS_FAIL,
    PLAN_DETAILS_RESET,

    USER_PLAN_DETAILS_REQUEST,
    USER_PLAN_DETAILS_SUCCESS,
    USER_PLAN_DETAILS_FAIL,
    USER_PLAN_DETAILS_RESET,
 
} from '../constants/planConstants'
import axios from 'axios'


export const createPlan = (name, price, numberOfAssignments, description) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PLAN_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/plans/add/`,
            {'name':name, 'price':price,'numberOfAssignments':numberOfAssignments, 'description':description},
            config
        )

        dispatch({
            type: PLAN_CREATE_SUCCESS,
            payload: data
        })



    } catch (error) {
        dispatch({
            type: PLAN_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listPlans = ()=> async (dispatch, getState)=>{
    try{
        
        dispatch({
            type: PLAN_LIST_REQUEST 
        })
        
       
        const {data} = await axios.get(
            '/api/plans/'
        )


        dispatch({
            type: PLAN_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: PLAN_LIST_FAIL,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail 
            : error.message,
        })
    }
}

export const getPlanDetails = (planId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PLAN_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/plans/${planId}/`,
            config
        )

        dispatch({
            type: PLAN_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: PLAN_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const payPlan = (id, paymentResult) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PLAN_PAY_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/userplans/${id}/pay`,
            paymentResult, 
            config
        )

        dispatch({
            type: PLAN_PAY_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: PLAN_PAY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getUserPlanDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_PLAN_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/plans/getuserplan`,
            config
        )

        dispatch({
            type: USER_PLAN_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_PLAN_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

