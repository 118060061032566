import {
    ASSIGNMENT_LIST_REQUEST, 
    ASSIGNMENT_LIST_SUCCESS, 
    ASSIGNMENT_LIST_FAIL,

    ASSIGNMENT_CREATE_REQUEST, 
    ASSIGNMENT_CREATE_SUCCESS, 
    ASSIGNMENT_CREATE_FAIL,
    ASSIGNMENT_CREATE_RESET,
    
    ASSIGNMENT_LIST_MY_REQUEST, 
    ASSIGNMENT_LIST_MY_SUCCESS, 
    ASSIGNMENT_LIST_MY_FAIL,
    ASSIGNMENT_LIST_MY_RESET,

    ASSIGNMENT_DETAILS_REQUEST,
    ASSIGNMENT_DETAILS_SUCCESS,
    ASSIGNMENT_DETAILS_FAIL,

    ASSIGNMENT_DELETE_REQUEST,
    ASSIGNMENT_DELETE_SUCCESS,
    ASSIGNMENT_DELETE_FAIL, 

    ASSIGNMENT_RESOURCE_DETAILS_REQUEST,
    ASSIGNMENT_RESOURCE_DETAILS_SUCCESS,
    ASSIGNMENT_RESOURCE_DETAILS_FAIL,

    ASSIGNMENTE_DELETE_REQUEST,
    ASSIGNMENTE_DELETE_SUCCESS,
    ASSIGNMENTE_DELETE_FAIL, 

   
} from '../constants/assignmentConstants'
import axios from 'axios'

export const listAssignments = ()=> async (dispatch, getState)=>{
    try{
        
        dispatch({
            type: ASSIGNMENT_LIST_REQUEST 
        })
        
        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
        }
        }
        const {data} = await axios.get(
            '/api/assignments/',  config
        )


        dispatch({
            type: ASSIGNMENT_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: ASSIGNMENT_LIST_FAIL,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail 
            : error.message,
        })
    }
}

export const listMyAssignments = ()=> async (dispatch, getState)=>{
    try{
        
        dispatch({
            type: ASSIGNMENT_LIST_MY_REQUEST 
        })
        
        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
        }
        }
        const {data} = await axios.get(
            '/api/assignments/myassignments',  config
        )


        dispatch({
            type: ASSIGNMENT_LIST_MY_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: ASSIGNMENT_LIST_MY_FAIL,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail 
            : error.message,
        })
    }
}

export const createAssignment = (board, classname, subject, topic, description) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ASSIGNMENT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/assignments/add/`,
            {'board':board, 'classname':classname,'subject':subject, 'topic':topic, 'description': description},
            config
        )

        dispatch({
            type: ASSIGNMENT_CREATE_SUCCESS,
            payload: data
        })



    } catch (error) {
        dispatch({
            type: ASSIGNMENT_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const getAssignmentDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ASSIGNMENT_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/assignments/${id}/`,
            config
        )

        dispatch({
            type: ASSIGNMENT_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ASSIGNMENT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getAssignmentResourceDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ASSIGNMENT_RESOURCE_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/assignmentresources/${id}/`,
            config
        )

        dispatch({
            type: ASSIGNMENT_RESOURCE_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ASSIGNMENT_RESOURCE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteAssignment = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ASSIGNMENT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/assignments/delete/${id}/`,
            config
        )

        dispatch({
            type: ASSIGNMENT_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ASSIGNMENT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
