import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from '../components/Loader'
import {LinkContainer} from 'react-router-bootstrap'
import {Form, Button, Card, Row, Col, Table} from 'react-bootstrap'

function HeadFirstImages() {
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreImages, setHasMoreImages] = useState(true);
  
    const fetchImages = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('/api/images/');
        setImages([...images, ...response.data]);
        setHasMoreImages(response.data.length > 0);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    
    useEffect(() => {
        fetchImages();
    
        const handleScroll = () => {
          const windowHeight = window.innerHeight;
          const scrollPosition = window.scrollY + windowHeight;
          const container = document.getElementById('image-container');
          const containerHeight = container.offsetHeight;
    
          if (hasMoreImages && !isLoading && scrollPosition >= containerHeight) {
            fetchImages();
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => window.removeEventListener('scroll', handleScroll);
      }, [hasMoreImages, isLoading]);
  return (
    <div id="image-container"> 

        <h5>Head First Revisions
             <LinkContainer to='/'>
                <button type="button" className="btn-close float-end" aria-label="Close"></button>
             </LinkContainer>
        </h5>
        <hr></hr>
        
        {images.map((image) => (
        <img key={image.src} src={image.src} alt={image.alt} />
        ))}
        {isLoading && <Loader />}
        {!hasMoreImages && <p>No more images.</p>}
  </div>
  )
}

export default HeadFirstImages
