import React, { useState , useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {LinkContainer} from 'react-router-bootstrap'
import {Form, Button, Card, Row, Col, Table} from 'react-bootstrap'
import {useSelector, useDispatch} from 'react-redux'
import { listMyAssignments } from '../actions/assignmentActions'
import Upload from '../components/Upload'
import {useNavigate} from 'react-router-dom';
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserPlanDetails } from '../actions/planActions'


function UserScreen() {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const assignmentsListMy  = useSelector(state => state.assignmentsListMy)
    const { loading: loadingAssignments, error: errorAssignments, assignments} = assignmentsListMy

    const userPlanDetails = useSelector(state => state.userPlanDetails)
    const { userPlan } = userPlanDetails

    
    useEffect(() => {
            if (!userInfo) {
                navigate('/login')
            }else{
                dispatch(getUserPlanDetails())
                dispatch(listMyAssignments())
            }
        }, [dispatch, navigate, userInfo])

    return (
      <div>
       <h4>Assignments &nbsp;&nbsp;
          { userPlan && userInfo && userInfo.isAdmin !== true?(<LinkContainer className= '' to='/newassignment'><Button className='' variant='success'>
            New Assignment
                    </Button></LinkContainer>):(<div><hr></hr><Message variant='secondary'>You do not have an active plan. Please buy a plan to request for assignmentsts.</Message></div>)}
                    </h4>
                   
                {loadingAssignments
                ? <Loader></Loader>
            : errorAssignments? <Message variant='danger'>{errorAssignments}</Message>
            :(
       <Table striped bordered hover responsive className='table-lg my-3'>
           <thead>
               <th>Topic</th>
               <th>Details</th>
               <th>Request Date</th>
               <th></th>
           </thead>

           <tbody>
               {assignments.map(assignment =>(
                    <LinkContainer  to={`/assignments/${assignment._id}`}>
                        <tr key = {assignment._id}>
                       <td>{assignment.topic}</td>
                       <td>{assignment.board} &nbsp; {assignment.className} &nbsp; {assignment.subject} &nbsp; {assignment.description} </td>
                       <td>{assignment.createdAt.substring(0, 10)}</td>
                       <td>{assignment.evaluation_image_submitted? <i class="fas fa-check-circle"></i>:(assignment.response_image_submitted?
                        <i class="far fa-check-circle"></i>:(assignment.questions_image_submitted?
                            <i class="fas fa-check-double"></i>:(<i class="fa fa-check" ></i>))) }
                        </td>
                   </tr>
                    </LinkContainer>
                  
               ))}
           </tbody>
       </Table>
   )
   }
   
   </div>
    );
  }
  
  export default UserScreen;