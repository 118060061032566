import React, {useState, useEffect} from 'react'

import {Form, Button, Row, Col, Card} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import Loader from '../components/Loader'
import { getUserDetails, updateUserProfile } from '../actions/userActions'

import {useNavigate} from 'react-router-dom';
import Message from '../components/Message'
import {USER_UPDATE_PROFILE_RESET} from '../constants/userConstants'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { listPlans, getUserPlanDetails } from '../actions/planActions'
import { listAssignments } from '../actions/assignmentActions'


function ProfileScreen() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()
    
  
    const navigate = useNavigate();
  
  
    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const updateUser = useSelector(state => state.updateUserProfile)
    const { success } = updateUser

    const userPlanDetails = useSelector(state => state.userPlanDetails)
    const { userPlan } = userPlanDetails


  
    useEffect(() => {
          if (!userInfo) {
              navigate('/login')
          }else{
            if(!user || !user.name || success || userInfo._id !== user._id){
                dispatch({type: USER_UPDATE_PROFILE_RESET})
                dispatch(getUserDetails('profile'))
                dispatch(listAssignments())
                dispatch(getUserPlanDetails())
            }else{
                setName(user.name)
                setEmail(user.email)
            }
          }
      }, [dispatch, navigate, userInfo, user, success])
  
      const submitHandler = (e) => {
          e.preventDefault()
          if(password !== confirmPassword){
            setMessage("Password do not match")
          } else{
            dispatch(updateUserProfile({
                'id': user._id,
                'name': name,
                'email': email,
                'password': password,

            }))
          }
          
          
      }


  return (
    <Row>
        <Col md={6}>

            <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
        >
        <Tab eventKey="profile" title=<h5>Your Profile</h5>>
            <p><b>Name: </b>{name}</p>
            <p><b>email: </b>{email}</p>
        </Tab>
        <Tab eventKey="update-profile" title=<i class="fas fa-edit"></i>>
             
            
            
             {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}
            <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
                <Form.Label> Name</Form.Label>
                <Form.Control required type= 'name' placeholder='Enter name' Value={name} 
                onChange={(e)=> setName(e.target.value)}></Form.Control>

            </Form.Group>

            <Form.Group controlId='email'>
                <Form.Label> Email Address</Form.Label>
                <Form.Control required type= 'email' placeholder='Enter Email' Value={email} 
                onChange={(e)=> setEmail(e.target.value)}></Form.Control>

            </Form.Group>

            <Form.Group controlId='password'>
                <Form.Label> Password</Form.Label>
                <Form.Control  type= 'password' placeholder='Enter Password' Value={password} 
                onChange={(e)=> setPassword(e.target.value)}></Form.Control>

            </Form.Group>

            <Form.Group controlId='passwordConfirm'>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control  type= 'password' placeholder='Confirm Password' Value={confirmPassword} 
                onChange={(e)=> setConfirmPassword(e.target.value)}></Form.Control>

            </Form.Group>
            <br></br>
            <Button type = 'submit' variant='primary'>Update</Button>
        </Form>
        </Tab>
        </Tabs>
            
           
           
            
        </Col>
        <Col md={6}>
            
                <h4>Current plan</h4>
                <hr></hr>
                
                {
                                  !userPlan &&  userInfo && !userInfo.isAdmin && (
                                    <Message variant='light'>You do not have an active plan! Upgrade with your convenient plan.</Message>
                                  )
                              }
                        {
                                  userPlan &&  userInfo && !userInfo.isAdmin && (
                                    <Card className='py-2 px-2' key = {userPlan._id} >
                                            <h6>{userPlan.name}</h6>
                                            <small >Rs.{userPlan.price}</small>
                                       
                                        <small>{userPlan.description}</small>
                                        <small><b>No of assignments left in the current plan:</b>&nbsp; &nbsp;{userPlan.numberOfAssignments}</small>
                                    </Card>
                                   
                                  )
                              }
        </Col>
    </Row>
  )
}

export default ProfileScreen