import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import {Form, Button, Row, Col} from 'react-bootstrap'

import Loader from '../components/Loader'
import { createSubject } from '../actions/subjectActions'
import FormContainer from '../components/FormContainer'
import {useNavigate} from 'react-router-dom';
import Message from '../components/Message'
import{SUBJECT_CREATE_RESET} from '../constants/subjectConstants'
import {LinkContainer} from 'react-router-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

function CreateSubjectScreen() {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const subjectCreate = useSelector(state => state.subjectCreate)
    const {subject, error, success} = subjectCreate

    useEffect(()=>{
        if(success){
            navigate('/admin/admintoolsscreen')
            dispatch({type:SUBJECT_CREATE_RESET})
        }
      },[success, navigate])
      
      const submitHandler = (e) => {
        e.preventDefault()
        
          dispatch(createSubject(name, description))  
      }
    
  return (
    <div>
       <FormContainer>
        <h5>New Subject <LinkContainer to='/admin/admintoolsscreen'>
                                <button type="button" className="btn-close float-end" aria-label="Close"></button>
                            </LinkContainer></h5>
        
        <hr></hr>
      
        <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
                <Form.Label> Name</Form.Label>
                <Form.Control required type= 'input' placeholder='Name' Value={name} 
                onChange={(e)=> setName(e.target.value)}></Form.Control>

            </Form.Group>


            <Form.Group controlId='classname'>
                <Form.Label> Description</Form.Label>
                 <textarea class="form-control" required type= 'textarea' placeholder='Description' Value={description} 
                onChange={(e)=> setDescription(e.target.value)} rows="3"></textarea>

            </Form.Group>
            <br></br>
            <Button type = 'submit' variant='primary'>Create Subject</Button>
        </Form>
        
    
    </FormContainer>
    </div>
  )
}

export default CreateSubjectScreen
