import React from 'react'
import {LinkContainer} from 'react-router-bootstrap'
function ReturnPolicyScreen() {
  return (
    <div>
      <h5>Return Policy 
        <LinkContainer to='/'>
                                <button type="button" className="btn-close float-end" aria-label="Close"></button>
                            </LinkContainer>
                            
         </h5> 
         <hr></hr>
         <small>
           <b>weeval </b> does not offer a traditional return policy as it is a service, not a physical product. However, we are committed to your satisfaction.
        </small>
        <ul>
            <li>
                <small>
                    <b>Free Trial:</b> weeval offers a free trial period. If you are not satisfied with weeval during the trial period, you can simply cancel your account and you will not be charged.
       
                </small>
            </li>
            <li>
                <small>
                    <b>Subscription Cancellation:</b> 
                    You can cancel your weeval subscription at any time. 
                    There are no refunds for unused portions of your subscription.
                </small>
            </li>
        </ul>
        <small>
        If you have any questions about our return policy, please contact us at [Insert Email Address].
        </small>
        
    </div>
  )
}

export default ReturnPolicyScreen
