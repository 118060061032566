
import {
    SUBJECT_LIST_REQUEST, 
    SUBJECT_LIST_SUCCESS, 
    SUBJECT_LIST_FAIL,
    
    SUBJECT_CREATE_REQUEST, 
    SUBJECT_CREATE_SUCCESS, 
    SUBJECT_CREATE_FAIL,
    SUBJECT_CREATE_RESET,

    
} from '../constants/subjectConstants'
export const subjectCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBJECT_CREATE_REQUEST:
            return {
                loading: true
            }

        case SUBJECT_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                subject: action.payload
            }

        case SUBJECT_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case SUBJECT_CREATE_RESET:
            return {}


        default:
            return state
    }
}

export const subjectListReducer = (state = {subjects:[]}, action)=>{
    switch(action.type){
        case SUBJECT_LIST_REQUEST:
            return {loading:true, subjects:[]}

        case SUBJECT_LIST_SUCCESS:
            return {loading:false, subjects:action.payload}

        case SUBJECT_LIST_FAIL:
            return {loading:false, error:action.payload}

        default: 
            return state
    }
}