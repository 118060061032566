
import {
    CLASSNAME_LIST_REQUEST, 
    CLASSNAME_LIST_SUCCESS, 
    CLASSNAME_LIST_FAIL,
    
    CLASSNAME_CREATE_REQUEST, 
    CLASSNAME_CREATE_SUCCESS, 
    CLASSNAME_CREATE_FAIL,
    CLASSNAME_CREATE_RESET,

    CLASSNAME_DELETE_REQUEST,
    CLASSNAME_DELETE_SUCCESS,
    CLASSNAME_DELETE_FAIL, 
    
} from '../constants/classnameConstants'

import axios from 'axios'


export const createClassname = (name, description) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CLASSNAME_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/classnames/add/`,
            {'name':name,  'description':description},
            config
        )

        dispatch({
            type: CLASSNAME_CREATE_SUCCESS,
            payload: data
        })



    } catch (error) {
        dispatch({
            type: CLASSNAME_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listClassnames = ()=> async (dispatch, getState)=>{
    try{
        
        dispatch({
            type: CLASSNAME_LIST_REQUEST 
        })
        
       
        const {data} = await axios.get(
            '/api/classnames/'
        )


        dispatch({
            type: CLASSNAME_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: CLASSNAME_LIST_FAIL,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail 
            : error.message,
        })
    }
}


export const deleteClassname = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CLASSNAME_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/classnames/delete/${id}/`,
            config
        )

        dispatch({
            type: CLASSNAME_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: CLASSNAME_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
