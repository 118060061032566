import './App.css';

import Footer from './components/Footer'
import Header from './components/Header'

import { Container } from 'react-bootstrap';


import HomeScreen from './screens/HomeScreen';
import TermsAndConditionsScreen from './screens/TermsAndConditionsScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import ReturnPolicyScreen from './screens/ReturnPolicyScreen';
import BoardScreen from './screens/BoardScreen';
import LoginScreen from './screens/LoginScreen';
import PasswordResetRequestScreen from './screens/PasswordResetRequestScreen';
import RegisterScreen from './screens/RegisterScreen';
import CreateAssignmentScreen from './screens/CreateAssignmentScreen';
import CreatePlanScreen from './screens/CreatePlanScreen';
import PaymentScreen from './screens/PaymentScreen';
import PostPayScreen from './screens/PostPayScreen';
import CreateHeadFirstRevisionScreen from './screens/CreateHeadFirstRevisionScreen';
import HeadFirstRevisionEditScreen from './screens/HeadFirstRevisionEditScreen';
import HeadFirstImagesDisplayScreen from './screens/HeadFirstImagesDisplayScreen';

import UserScreen from './screens/UserScreen';
import ProfileScreen from './screens/ProfileScreen';
import UserListScreen from './screens/UserListScreen';
import UserEditScreen from './screens/UserEditScreen';
import AssignmentListScreen from './screens/AssignmentListScreen';
import AssignmentScreen from './screens/AssignmentScreen';
import UserAssignmentScreen from './screens/UserAssignmentScreen';
import AdminToolsScreen from './screens/AdminToolsScreen';
import CreateSubjectScreen from './screens/CreateSubjectScreen';
import CreateClassnameScreen from './screens/CreateClassnameScreen';
import CreateBoardScreen from './screens/CreateBoardScreen';

import PlanListScreen from './screens/PlanListScreen';
import HeadFirstImages from './screens/HeadFirstImages';
import HeadFirstImageStaffScreen from './screens/HeadFirstImageStaffScreen';
import {HashRouter as Router, Route, Routes} from 'react-router-dom'


function App() {
  return (
    <Router>
        <Header />
          <main className='py-3'>
            <Container>
               <Routes>
                < Route path='/login/' element = {<LoginScreen />} />
                < Route path='/ternsandconditions/' element = {<TermsAndConditionsScreen />} />
                < Route path='/privacypolicy/' element = {<PrivacyPolicyScreen />} />
                < Route path='/returnpolicy/' element = {<ReturnPolicyScreen />} />
                < Route path='/' element = {<HomeScreen />} exact />
                < Route path='/register/' element = {<RegisterScreen />} />
                < Route path='/passwordresetrequest/' element = {<PasswordResetRequestScreen />} />

                <Route path='/board/:id' element = {<BoardScreen />} />

                < Route path='/userscreen' element = {<UserScreen />} />
                < Route path='/profile' element = {<ProfileScreen />} />
                < Route path='admin/userlist' element = {<UserListScreen />} />
                < Route path='admin/user/:id/edit' element = {<UserEditScreen />} />

                < Route path='/newassignment' element = {<CreateAssignmentScreen />} />
                < Route path='admin/assignmentlist' element = {<AssignmentListScreen />} />
                <Route path='/assignments/:id/' element={<UserAssignmentScreen />} />
                <Route path='admin/assignments/:id/:userId/' element={<AssignmentScreen />} />
                < Route path='admin/headfirstrevisions' element = {<HeadFirstImageStaffScreen />} />
                < Route path='admin/headfirstdisplay' element = {<HeadFirstImagesDisplayScreen />} />
                < Route path='/headfirstdisplay' element = {<HeadFirstImagesDisplayScreen />} />
                < Route path='admin/headfirstrevision/:id/edit' element = {<HeadFirstRevisionEditScreen />} />

                < Route path='/planList' element = {<PlanListScreen />} />
                < Route path='/newplan' element = {<CreatePlanScreen />} />
                < Route path='/paymentScreen/:planId' element = {<PaymentScreen />} />
                < Route path='/postpayscreen' element = {<PostPayScreen />} />

                < Route path='/headFirstRevisions' element = {<HeadFirstImages />} />
                < Route path='/newheadfirstimage' element = {<CreateHeadFirstRevisionScreen />} />

                < Route path='/admin/admintoolsscreen' element = {<AdminToolsScreen />} />
                < Route path='/newsubject' element = {<CreateSubjectScreen />} />
                < Route path='/newclassname' element = {<CreateClassnameScreen />} />
                < Route path='/newboard' element = {<CreateBoardScreen />} />
                
              </Routes>
            </Container>
            
          </main>
        <Footer />
      </Router>
  );
}

export default App;
