import React from 'react'
import {LinkContainer} from 'react-router-bootstrap'

function TermsAndConditionsScreen() {
  return (
    <div>
        <h5>Terms and Conditions 
        <LinkContainer to='/'>
                                <button type="button" className="btn-close float-end" aria-label="Close"></button>
                            </LinkContainer>
                            
         </h5> 
         <hr></hr>
        <i>Effective Date: 28th May, 2024 -  &nbsp;</i>

        <small>These Terms and Conditions govern your access to and use of the weeval web application ("weeval," "Service"). 
        By accessing or using weeval, you agree to be bound by these Terms.</small>

        <h6 className='mt-3'>Use of the Service:</h6>
        <small>You must be at least 13 years old to use weeval.</small>
        <small>You are responsible for maintaining the confidentiality of your account login information.</small>
        <small>You are responsible for all activity that occurs under your account.</small>
        <small>You agree not to use weeval for any illegal or unauthorized purpose.</small>
        
        
        <h6 className='mt-3'>Content:</h6>
        <small>You are responsible for all content you upload to weeval.</small>
        <small>You will not upload any content that is infringing, obscene, defamatory, or otherwise unlawful.</small>
        
        
        <h6 className='mt-3'>Intellectual Property:</h6>
        <small>weeval and its underlying technology are protected by copyright and other intellectual property laws.</small>
        
        <h6 className='mt-3'>Termination:</h6>
        
        <small>We may terminate your access to weeval for any reason, at any time, without notice.</small>
        
        <h6 className='mt-3'>Disclaimer:</h6>
        
        <small>weeval is provided "as is" and without warranty 
            of any kind. We disclaim all warranties, express or implied, including, 
            but not limited to, the implied warranties of merchantability, fitness for a
             particular purpose, and non-infringement.</small>
        
        <h6 className='mt-3'>Limitation of Liability:</h6>
        
        <small>  We will not be liable for any damages arising out of your use of weeval, including, 
            but not limited to, direct, indirect, 
            incidental, consequential, or punitive damages.</small>
       

        <h6 className='mt-3'>Governing Law:</h6>
        
        <small>These Terms will be governed by and construed in accordance 
            with the laws of [Insert Jurisdiction].</small>
        

        <h6 className='mt-3'>Dispute Resolution:</h6>
        
        <small>Any dispute arising out of or relating to these Terms will 
            be resolved by [Insert Dispute Resolution Mechanism].</small>
        

        <h6 className='mt-3'>Entire Agreement:</h6>
        <small>These Terms constitute the entire agreement between you and us regarding your use of weeval.</small>

        
    </div>
  )
}

export default TermsAndConditionsScreen
