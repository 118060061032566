import {
    HFIMAGE_CREATE_REQUEST,
    HFIMAGE_CREATE_SUCCESS,
    HFIMAGE_CREATE_FAIL,
    HFIMAGE_CREATE_RESET,

    HFIMAGE_UPDATE_REQUEST,
    HFIMAGE_UPDATE_SUCCESS,
    HFIMAGE_UPDATE_FAIL,
    HFIMAGE_UPDATE_RESET,

    HFIMAGE_LIST_REQUEST, 
    HFIMAGE_LIST_SUCCESS, 
    HFIMAGE_LIST_FAIL,

    HFIMAGE_DETAILS_REQUEST,
    HFIMAGE_DETAILS_SUCCESS,
    HFIMAGE_DETAILS_FAIL,

    HFIMAGE_DELETE_REQUEST,
    HFIMAGE_DELETE_SUCCESS,
    HFIMAGE_DELETE_FAIL, 
} from '../constants/HeadFirstConstants'
import axios from 'axios'

export const createHeadFirst = (board, classname, subject, topic, description) => async (dispatch, getState) => {
    try {
        dispatch({
            type: HFIMAGE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        
        const { data } = await axios.post(
            `/api/headfirstimages/add/`,
            {'board':board, 'classname':classname,'subject':subject, 'topic':topic, 'description': description},
            config
        )
        dispatch({
            type: HFIMAGE_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: HFIMAGE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listHfs = ()=> async (dispatch, getState)=>{
    try{
        
        dispatch({
            type: HFIMAGE_LIST_REQUEST 
        })
        
        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
        }
        }
        const {data} = await axios.get(
            '/api/headfirstimages/',  config
        )


        dispatch({
            type: HFIMAGE_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: HFIMAGE_LIST_FAIL,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail 
            : error.message,
        })
    }
}

export const updateHeadFirst = (hfrevision) => async (dispatch, getState) => {
    try {
        dispatch({
            type: HFIMAGE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/headfirstimages/update/${hfrevision._id}/`,
            hfrevision,
            config
        )
        dispatch({
            type: HFIMAGE_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: HFIMAGE_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: HFIMAGE_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listHeadFirstDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: HFIMAGE_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/headfirstimages/${id}`)

        dispatch({
            type: HFIMAGE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: HFIMAGE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteHeadFirst = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: HFIMAGE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/headfirstimages/delete/${id}/`,
            config
        )

        dispatch({
            type: HFIMAGE_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: HFIMAGE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
