import React, {useState, useEffect} from 'react'

import { useParams } from 'react-router-dom'
import { UseDispatch, useDispatch, useSelector } from 'react-redux'
import {listBoardDetails} from '../actions/boardActions'

import Alert from 'react-bootstrap/Alert';
import Loader from '../components/Loader'

function BoardScreen({match}) {
  const id = useParams()
  const dispatch = useDispatch()
  
  const boardDetails = useSelector(state => state.boardDetails)
  const {error, loading, board} = boardDetails

  useEffect(()=>{
    dispatch(listBoardDetails(id))
    
  }, [])

 
  return (
    <div>
       {loading ? 
           <Loader></Loader>
            :error ? <Alert variant="danger"  dismissible>
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
              {error}
            </p>
          </Alert>
            :<div>
              <h3>{board.name}</h3>
          {board.description}
            </div> }

    </div>
   
  )
}

export default BoardScreen