import React, {useState, useEffect} from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import {Table, Form, Button, Row, Col} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import Loader from '../components/Loader'
import { listUsers, deleteUser, listActiveUsers } from '../actions/userActions'

import {useNavigate} from 'react-router-dom';
import Message from '../components/Message'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function UserListScreen() {
  const [description, setDescription] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const userList = useSelector(state => state.userList)
  const {loading, error, users} = userList

  const activeUserList = useSelector(state => state.activeUserList)
  const {loading:activeUsersLoading, error:activeUSersError, activeUSers} = activeUserList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const userDelete = useSelector(state => state.userDelete)
  const { success: successDelete } = userDelete


  useEffect(()=>{
    if (userInfo && userInfo.isAdmin) {
        dispatch(listUsers())
        dispatch(listActiveUsers())
    } else {
        navigate('/login')
    }
  }, [dispatch, navigate, successDelete, userInfo])

  const deleteHandler = (id) => {

    if (window.confirm('Are you sure you want to delete this user?'))
    {
        dispatch(deleteUser(id))
        
    }
}

const filterUsers = () => {
    if(users){
        return users.filter((user) => {
            return (
                (description==="" || user.email.toLowerCase().includes(description.toLowerCase()))
            );
        });
    }
   
};

const filterActiveUsers = () => {
    if(activeUSers){
        return activeUSers.filter((user) => {
            return (
                (description==="" || user.email.toLowerCase().includes(description.toLowerCase()))
            );
        });
    }
   
};

const filteredUsers = filterUsers();
const filteredActiveUsers = filterActiveUsers();
  return (
    <div>
         <Tabs
      defaultActiveKey="allusers"
      id="users-active-all-id"
      className="mb-3">
        <Tab eventKey="allusers" title="All Users">
        <Form className="filters my-3">
          <Row>
            <Col  md={6}>
            <Form.Group className="filter" controlId="formDescription">
              
                 <Form.Control class="form-control" required type= 'input' placeholder='key word' Value={description} 
                onChange={(e)=> setDescription(e.target.value)} rows="3"></Form.Control>
            </Form.Group>
           
            </Col>
           
            </Row>
            </Form>
        {loading
        ? <Loader></Loader>
    : error? <Message variant='danger'>{error}</Message>
    :(
        <Table striped bordered hover responsive className='table-lg'>
            <thead>
                <th>Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Admin</th>
                <th></th>
            </thead>

            <tbody>
                {filteredUsers.map(user =>(
                    <tr key = {user._id}>
                        <td>{user._id}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.isAdmin ?
                        (
                            <i className='fas fa-check' style={{color:'green'}}></i>
                        ):(
                            <i className='fas fa-check' style={{color:'red'}}></i>
                        )}</td>
                        <td>
                                            <LinkContainer to={`/admin/user/${user._id}/edit`}>
                                                <Button variant='light' className='btn-sm'>
                                                    <i className='fas fa-edit'></i>
                                                </Button>

                                               
                                            </LinkContainer>
                                            <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(user._id)}>
                                                <i className='fas fa-trash'></i>
                                            </Button>
                                           
                                        </td>

                    </tr>
                ))}
            </tbody>
        </Table>
    )
    }
        </Tab>
        <Tab eventKey="activeusers" title="Active Users">
        <Form className="filters my-3">
          <Row>
            <Col  md={6}>
            <Form.Group className="filterActive" controlId="formDescription">
              
                 <Form.Control class="form-control" required type= 'input' placeholder='key word' Value={description} 
                onChange={(e)=> setDescription(e.target.value)} rows="3"></Form.Control>
            </Form.Group>
           
            </Col>
           
            </Row>
            </Form>
        {activeUsersLoading
        ? <Loader></Loader>
    : error? <Message variant='danger'>{activeUSersError}</Message>
    :(
        <Table striped bordered hover responsive className='table-lg'>
            <thead>
                <th>Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Admin</th>
                <th></th>
            </thead>

            <tbody>
                {filteredActiveUsers.map(user =>(
                    <tr key = {user._id}>
                        <td>{user._id}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.isAdmin ?
                        (
                            <i className='fas fa-check' style={{color:'green'}}></i>
                        ):(
                            <i className='fas fa-check' style={{color:'red'}}></i>
                        )}</td>
                        <td>
                                            <LinkContainer to={`/admin/user/${user._id}/edit`}>
                                                <Button variant='light' className='btn-sm'>
                                                    <i className='fas fa-edit'></i>
                                                </Button>

                                               
                                            </LinkContainer>
                                            <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(user._id)}>
                                                <i className='fas fa-trash'></i>
                                            </Button>
                                           
                                        </td>

                    </tr>
                ))}
            </tbody>
        </Table>
    )
    }
        </Tab>
    </Tabs>
       
       
    
    </div>
  )
}

export default UserListScreen